<template functional>
    <tbody> <!-- some browsers won't keep scroll unless tbodys are added -->
        <tr>
            <td>
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--compact" v-bind:to="{
                    name: 'tx',
                    params: { hash: props.hash },
                }">
                    <icon-chain/>
                </ui-link>
            </td>
            <td style="text-align: center;" v-text="props.seqno"/>
            <td>
                <ui-address v-bind:address="props.account"/>
            </td>
            <td v-text="props.lt"/>
            <td v-text="props.hash"/>
        </tr>
    </tbody>
</template>

<script>
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';

export default {
    props: {
        seqno: Number,
        hash: String,
        account: String,
        lt: String,
    },

    components: { IconChain },
};
</script>
