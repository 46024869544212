<template>
    <div class="search-result-domain">
        {{name}} <span class="search-result-domain__tld" v-text="tld"/>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        addressType: String,
    },

    computed: {
        tld() {
            return this.addressType === 'telegram_username'
                ? '.t.me'
                : '.ton';
        },
    },
};
</script>

<style lang="scss">
.search-result-domain {
    font-size: 15px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    &__tld {
        font-weight: normal;
        opacity: .6;
    }
}
</style>
