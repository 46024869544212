<script>
// import IconList from '@img/icons/material-duotone/list.svg?inline';
// import IconHistory from '@img/icons/material-duotone/history.svg?inline';
import UiTabs from '~/components/UiTabs.vue';
import Details from './Tabs/Details.vue';
import EventOverview from './Tabs/EventOverview.vue';

export default {
    props: {
        traceId: {
            type: String,
            default: null,
        },
        timeStart: {
            type: Number,
            default: null,
        },
        traceActions: {
            type: [Array, undefined],
            default: undefined,
        },
        trace: Object,
        traceEmpty: Boolean,
    },

    computed: {
        tabs() {
            return [{
                key: 'overview',
                text: this.$t('transactionPage.overview'),
                content: {
                    props: {
                        traceActions: this.traceActions,
                        traceEmpty: this.traceEmpty,
                        trace: this.trace,
                    },
                    eager: true,
                    component: EventOverview,
                },
            }, {
                key: 'details',
                text: this.$t('transactionPage.details'),
                content: {
                    props: {
                        traceId: this.traceId,
                        timeStart: this.timeStart,
                    },
                    eager: true,
                    component: Details,
                },
            }];
        },
    },

    render(createComponent) {
        return createComponent(UiTabs, {
            class: 'card card--tabbed tx-tabs-items-pd',
            props: {
                tabs: this.tabs,
                changeUrlHash: true,
            },
            on: this.$listeners,
            scopedSlots: this.$scopedSlots,
        });
    },

    components: {
        UiTabs,
        // Details,
        // IconList,
    },
};
</script>
