<script>
// This component extends the <router-link/> component and adds the language prefix to URL.
export default {
    props: {
        to: {
            type: Object,
            required: true,
        },
    },

    render(createElement) {
        const props = {
            to: this.$localizeRoute(this.to),
        };

        return createElement('router-link', { props }, this.$slots.default);
    },
};
</script>

<style lang="scss">
.router-link__no-style {
    display: block;
    color: inherit;
    &:hover {
        text-decoration: none;
    }
}
</style>
