<template>
    <section>
        <div class="verifier-admonition">
            <lightbulbIcon class="verifier-admonition__icon" />

            <div class="verifier-admonition__right">
                <h2 class="verifier-admonition__title">
                    {{ $t('address.contract.admonition.formats_title') }}
                </h2>

                <div class="verifier-admonition__text" v-html="$t('address.contract.admonition.formats_text')" />
            </div>
        </div>

        <section class="tabs-address-formats source-viewer source-viewer--tabbed">
            <nav class="source-viewer-nav">
                <div
                    v-for="(_, key) in formats"
                    v-bind:key="'tab-' + key"
                    class="source-viewer-nav__tab"
                    v-on:click="currentTab = key"
                    v-bind:class="{ 'source-viewer-nav__tab--active': currentTab === key }">
                    <span>
                        {{ key }}
                    </span>
                </div>
            </nav>

            <main class="source-viewer-code">
                <ui-copy-button v-if="!isLoading" class="source-viewer-code__copy" v-bind:copy="formats[currentTab]" />

                <div v-if="isLoading" class="source-viewer-code__skeletons">
                    <span v-pre class="skeleton">0:8c3912345612345612345612345612345612345612345612345647ab3</span>
                </div>

                <div v-else class="config-content-viewer">
                    {{ formats[currentTab] }}
                </div>
            </main>
        </section>
    </section>
</template>

<script>
import lightbulbIcon from '@img/icons/material-duotone/lightbulb.svg?vue';
import { detectAddress } from '~/api';

export default {
    props: ['address'],
    data() {
        return {
            formats: {
                'hex': undefined, // eslint-disable-line
                'bounceable': undefined, // eslint-disable-line
                'non-bounceable': undefined, // eslint-disable-line
            },
            currentTab: 'hex',
            isLoading: true,
        };
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async loadData() {
            const detect = await detectAddress(this.address);

            this.formats['hex'] = detect?.raw_form; // eslint-disable-line
            this.formats['bounceable'] = detect?.bounceable?.b64url; // eslint-disable-line
            this.formats['non-bounceable'] = detect?.non_bounceable?.b64url;

            this.isLoading = false;
        },
    },

    components: {
        lightbulbIcon,
    },
};
</script>

<style scoped lang="scss">
.tabs-address-formats {
    padding: 0 11px 12px;
    display: inline-block;
    width: auto;
    max-width: 100%;
    overflow-x: auto;
    box-sizing: border-box;

    .config-content-viewer {
        padding-right: 25px;
        overflow-x: auto;
    }
}
</style>
