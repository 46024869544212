<template>
    <div class="widget" style="position: relative; overflow: visible">
        <div class="widget-header">
            <div class="widget-title">
                {{ $t("indexpage.top_jettons") }}
                <a href="https://dyor.io/" target="_blank" class="muted">
                    <icon-title-help class="top-jetton-tooltip__icon"
                        v-on:mouseover.native="showDyorTooltip = true"
                        v-on:mouseleave.native="showDyorTooltip = false"
                    />
                </a>
                <div v-if="showDyorTooltip" class="custom-tooltip-wrapper" v-bind:style="$i18n.locale === 'en' ? 'left: 95px' : 'left: 55px'">
                    <span>{{ $t('jetton_filters.dyor.data_from' )}} DYOR.io</span>
                </div>
            </div>
            <div class="widget-link widget-apps-link">
                <ui-link v-bind:to="{ name: 'market' }">
                    {{ $t('indexpage.all_apps') }}
                </ui-link>
            </div>
        </div>
        <div class="widget-content">
            <top-jetton-skeleton v-if="!jettonsData" v-bind:rows="limit" />
            <div class="widget-jettons" v-else>
                <top-jetton-item
                    v-for="(jetton, index) in jettonsData"
                    v-bind:key="index"
                    v-bind:img="jetton.img"
                    v-bind:name="jetton.name"
                    v-bind:price="jetton.price"
                    v-bind:symbol="jetton.symbol"
                    v-bind:jetton="jetton.jetton"
                    v-bind:change="jetton.priceChange24h"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getTopMCJettonsList } from '~/api/dyor.js';
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import TopJettonItem from './TopJettonItem.vue';
import TopJettonSkeleton from './TopJettonItemSkeleton.vue';

export default {
    data() {
        return {
            jettonsData: undefined,
            limit: 3,
            showDyorTooltip: false,
        };
    },

    mounted() {
        this.getTrendingInfo();
    },

    methods: {
        async getTrendingInfo() {
            try {
                this.jettonsData = await getTopMCJettonsList(this.limit);
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        TopJettonItem,
        TopJettonSkeleton,
        IconTitleHelp,
    },
};
</script>

<style lang="scss">
.widget-jettons {
    padding: 12px 0 10px 0;
}

.widget-jettons-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    margin: 0 10px;
    border-radius: 10px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.widget-jettons a:hover {
    background: var(--body-light-muted-color);
}

.widget-jettons-content {
    align-items: start;
    flex-grow: 1;
    margin-left: 4px;
    .widget-jettons-name {
        font-size: 14px;
        color: var(--body-text-color);
        font-weight: 500;
    }
    .widget-jettons-symbol {
        font-size: 14px;
    }
}

.widget-jettons-data {
    align-items: end;
}

.widget-jettons-content,
.widget-jettons-data {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.widget-jettons-price {
    font-weight: 500;
    margin-left: 20px;
    font-size: 14px;
    color: var(--body-text-color);
}

.widget-jettons-img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
}

.widget-jettons-img img {
    width: 100%;
    vertical-align: top;
}

.top-jetton-tooltip__icon {
    fill: currentColor;
    width: 14px;
    height: 14px;
    margin: -4px 0 -4px 3px;
    transform: translateY(-2px);
    cursor: pointer;

    &:hover {
        color: var(--blue-bright);
        text-decoration: none;
    }
}

.custom-tooltip-wrapper {
    position: absolute;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    top: -30px;
    font-size: 13px;
    text-transform: none;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 400px;
    z-index: 1001;
}

.dyor-logo-widget {
    padding-bottom: 10px;
    text-align: center;
    font-size: 14px;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--blue-bright);
        }
    }

    &__icon {
        width: 15px;
        height: 15px;
        fill: currentColor;
        transform: translateY(3px);
        margin-left: 2px;
    }
}
</style>
