<template>
    <div class="addressbook-dropdown__item">
        <ui-link class="addressbook-dropdown__link"
            v-bind:to="{ name: type, params: { address: address }}"
            v-on:click="$emit('close-addressbook')"
        >
            <div class="addressbook-dropdown__item--name">
                <span v-if="name > 0" class="muted">
                    {{ $t('local_addressbook.untitled') }} {{ name }}
                </span>
                <span v-else>
                    {{ name }}
                </span>
            </div>
            <div class="muted addressbook-dropdown__item--address">
                <ui-address
                    v-bind:address="address"
                    v-bind:disabled="true"
                    v-bind:hide-name="true"
                />
            </div>
        </ui-link>
        <div class="addressbook-dropdown__controls">
            <icon-edit v-on:click.native="editItem"/>
            <icon-close v-on:click.native="removeItem" />
        </div>
    </div>
</template>

<script>
import IconClose from '@img/icons/tonscan/close.svg?vue';
import IconEdit from '@img/icons/tonscan/edit-pencil.svg?vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
        name: {
            type: [String, Number],
        },
        index: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
        },
    },

    methods: {
        editItem() {
            this.$emit('edit-address-name', this.index);
        },

        removeItem() {
            this.$store.dispatch('forgetAddressbookItem', { address: this.address });
        },
    },

    components: {
        IconClose,
        IconEdit,
    },
};
</script>

<style lang="scss">
.addressbook-dropdown {
    &__item {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;

        &:hover {
            background: var(--tx-table-hide-button-background);

            .addressbook-dropdown__controls {
                display: flex;
            }
        }

        &--name {
            font-weight: 500;
        }

        &--address {
            font-size: 12px;
            width: 95%;
        }
    }

    &__link {
        gap: 5px;
        padding: 10px 13px;
        white-space: nowrap;
        cursor: pointer;
        color: var(--body-text-color);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    &__controls {
        position: absolute;
        display: none;
        gap: 8px;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0 10px 0 30px;
        align-items: center;
        color: var(--body-muted-text-color);
        background: linear-gradient(to right, transparent 0%, var(--tx-table-hide-button-background) 30%, var(--tx-table-hide-button-background) 100%);

        svg {
            width: 20px;
            height: 20px;
            transition: .15s color;
            cursor: pointer;

            &:hover {
                color: var(--body-text-color);
            }
        }
    }
}
</style>
