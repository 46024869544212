<template>
    <section>
        <banner-screen />
        <section class="ads-page-container">
            <div class="ads-page-container__container">
                <banner-main banner-id="1" v-bind:banner-img="banner1" />
                <banner-main banner-id="4" v-bind:banner-img="banner3" />
                <banner-main banner-id="5" v-bind:banner-img="banner5" />
            </div>
            <div class="ads-page-container__container">
                <banner-main banner-id="3" v-bind:banner-img="banner2" />
                <banner-main banner-id="2" v-bind:banner-img="banner4" />
                <banner-main banner-id="6" v-bind:banner-img="banner6" />
            </div>
        </section>
        <p class="ads-page-header">{{ $t('ads_page.logos_header') }}</p>
        <section class="ads-page-logos">
            <a v-for="company in companies" v-bind:href="company.link" v-bind:key="company.link" target="_blank">
                <component v-bind:is="company.logo" />
            </a>
        </section>
        <div class="contact-us">
            <p class="contact-us__header">{{ $t('ads_page.footer.header') }}</p>
            <p class="contact-us__text muted">{{ $t('ads_page.footer.text') }}</p>
            <a href="https://t.me/adiutor" target="_blank" class="contact-us__button">{{ $t('ads_page.contact_us') }}</a>
        </div>
    </section>
</template>

<script>
import LogoMtw from '@img/advertise/logos/mtw.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoStakee from '@img/advertise/logos/stakee.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoCryptoBot from '@img/advertise/logos/crypto-bot.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoDedust from '@img/advertise/logos/dedust.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoDyor from '@img/advertise/logos/dyor.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoTon from '@img/advertise/logos/ton-org.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoGem from '@img/advertise/logos/gem.svg?vue'; // eslint-disable-line import/no-unresolved
import LogoMajor from '@img/advertise/logos/major.svg?vue'; // eslint-disable-line import/no-unresolved
import banner1 from '@img/advertise/banner1.png';
import banner2 from '@img/advertise/banner2.png';
import banner3 from '@img/advertise/banner3.png';
import banner4 from '@img/advertise/banner4.png';
import banner5 from '@img/advertise/banner5.png';
import banner6 from '@img/advertise/banner6.png';
import BannerMain from './banners/Banner.vue';
import BannerScreen from './banners/Screen.vue';

export default {
    data() {
        return {
            banner1,
            banner2,
            banner3,
            banner4,
            banner5,
            banner6,
            companies: [
                {
                    link: 'https://dyor.io/',
                    logo: LogoDyor,
                },
                {
                    link: 'https://t.me/CryptoBot',
                    logo: LogoCryptoBot,
                },
                {
                    link: 'https://ton.org/',
                    logo: LogoTon,
                },
                {
                    link: 'https://dedust.io/',
                    logo: LogoDedust,
                },
                {
                    link: 'https://mytonwallet.io/',
                    logo: LogoMtw,
                },
                {
                    link: 'https://t.me/gembot',
                    logo: LogoGem,
                },
                {
                    link: 'https://t.me/StakeeBot',
                    logo: LogoStakee,
                },
                {
                    link: 'https://t.me/major',
                    logo: LogoMajor,
                },
            ],
        };
    },

    components: {
        BannerScreen,
        BannerMain,
    },
};
</script>

<style scoped lang="scss">
.ads-page-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 30px 0 10px 0;
    box-sizing: border-box;

    &__column {
        width: 100%;
        max-height: 667px;
    }
}

.ads-page-header {
    font-weight: 800;
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 0;
    text-align: center;
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
}

.ads-page-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 16px 10px;
    justify-content: space-around;
    max-width: 800px;
    margin: 20px auto;

    svg {
        cursor: pointer;
        height: 37px;
        transition: .3s ease;
        color: var(--body-muted-text-color);
        &:hover {
            color: var(--body-text-color);
        }
    }
}

.contact-us {
    padding: 50px 0;
    text-align: center;
    margin-top: 10px;
    width: 100%;

    &__header {
        font-weight: 800;
        font-size: 28px;
        margin: 0;
        font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__button {
        display: inline-block;
        width: auto;
        text-align: center;
        border: none;
        border-radius: 8px;
        color: #FFF;
        background-color: var(--big-blue-button-background);
        padding: 10px 18px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        margin: 0 auto;

        &:hover {
            text-decoration: none;
            background-color: var(--big-blue-button-hover-background);
        }
    }
}

@media screen and (max-width: 1230px){
    .ads-page-container {
        grid-template-columns: 1fr;
        max-width: 600px;
        margin: 0 auto;
    }
}
</style>
