<template>
    <span class="ui-copy-button" v-on:click="copyToClipboard">
        <slot>{{value}}</slot>

        <template v-if="!hideIcon">
            <icon-copy v-show="!successVisible" class="ui-copy-button__icon"/>
            <icon-success v-if="successVisible" class="ui-copy-button__icon"/>
        </template>

        <input ref="fallback" type="text"
            v-if="fallbackValue"
            v-bind:value="fallbackValue">
    </span>
</template>

<script>
import IconCopy from '@img/icons/tonscan/copy-14.svg?vue';
import IconSuccess from '@primer/octicons/build/svg/check-16.svg?vue';
import { showToast } from '~/toast.js';

export default {
    props: {
        hideIcon: {
            type: Boolean,
            default: false,
        },
        successMessage: {
            type: String,
            default: 'Copied!',
        },
        copy: {
            type: [String, Number],
            required: true,
        },
        value: {
            type: [String, Number],
            required: false,
        },
    },

    data() {
        return {
            successVisible: false,
            fallbackValue: undefined,
        };
    },

    computed: {
        dataToCopy() {
            return (this.copy || this.value || '').toString();
        },
    },

    methods: {
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.dataToCopy);
            } catch {
                this.copyToClipboardFallback(this.dataToCopy);
            }

            this.onSuccess();
        },

        // Basically this is needed for development, because navigator.clipboard exists only on https:
        copyToClipboardFallback(text) {
            this.fallbackValue = text;
            this.$nextTick(() => {
                this.$refs.fallback.focus();
                this.$refs.fallback.select();
                document.execCommand('copy');
                this.fallbackValue = undefined;
            });
        },

        onSuccess() {
            showToast(this.successMessage);

            this.successVisible = true;

            setTimeout(() => {
                this.successVisible = false;
            }, 1800);
        },
    },

    components: {
        IconCopy, IconSuccess,
    },
};
</script>

<style lang="scss">
.ui-copy-button {
    position: relative;
    cursor: text;
    &__icon {
        fill: currentColor;
        user-select: none;
        opacity: .65;
        cursor: pointer;
        padding: 6px;
        margin: -6px -6px -8px -4px;
        width: 14px;
        height: 14px;
    }
    &:hover .ui-copy-button__icon {
        opacity: 1;
    }
    .clickable + & {
        margin-left: 0.5ex;
    }
}
.ui-copy-button-fallback {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    font-size: 0;
}
</style>
