<template>
    <section>
        <div class="card">
            <div class="card-title">
                {{$t('vesting.address')}}
                <a class="card-title-help" target="_blank"
                    v-bind:href="$t('vesting.title_help_link')">
                    <icon-title-help class="card-title-help__icon"/>
                    {{$t('common.what_is_this')}}
                </a>
            </div>

            <div style="flex-grow: 1;">
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('common.address')" />
                    <div class="card-row__value">
                        <ui-copy-button v-bind:copy="address" v-bind:successMessage="$t('address.info.copy_success')">
                            {{address}}
                        </ui-copy-button>

                        <span class="card-main-qr-button" v-on:click="qrModalVisible = true">
                            <icon-qr class="card-main-qr-button__icon"/>
                        </span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name" v-text="$t('vesting.owner')"/>
                    <div class="card-row__value" style="display: flex; align-items: center;">
                        <ui-address v-if="ownerAddress" v-bind:address="ownerAddress"/>
                        <span v-else class="skeleton">EQDCH6....Loh</span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name" v-text="$t('address.info.balance')"/>
                    <div class="card-row__value" v-if="wallet.balance == '0' || wallet.balance">
                        {{$ton(wallet.balance)}} TON

                        <template v-if="wallet.balance != '0'">
                            <span v-if="$store.state.exchangeRate" style="color: #717579">
                                ≈ <ui-fiat v-bind:tonValue="wallet.balance"/>
                            </span>
                        </template>

                        <span class="muted">&bull;</span>
                        <a v-bind:href="vestingLink" target="_blank" v-text="$t('vesting.link')"/>
                    </div>
                    <div v-else class="card-row__value">
                        <span class="skeleton">00000 TON ≈ 00000 USD</span>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('nominator_pool.start_date')"/>
                    <div class="card-row__value">
                        <ui-datetime v-if="startTime" v-bind:timestamp="startTime"/>
                        <span v-else class="skeleton">26.07.2023, 16:30:00</span>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('vesting.vesting')"/>
                    <div class="card-row__value card-row__value--flex-row">
                        <div class="card-row">
                            <div class="card-row__name" v-text="$t('vesting.total')"/>
                            <div class="card-row__value">
                                <span v-if="totalVested === undefined" class="skeleton">10000 TON</span>
                                <template v-else>
                                    {{$ton(totalVested)}} TON
                                </template>
                            </div>
                        </div>
                        <div class="card-row">
                            <div class="card-row__name" v-text="$t('vesting.available')"/>
                            <div class="card-row__value">
                                <span v-if="vestingAvailable === undefined" class="skeleton">10000 TON</span>
                                <template v-else>
                                    {{$ton(vestingAvailable)}} TON
                                </template>
                            </div>
                        </div>
                        <div class="card-row">
                            <div class="card-row__name" v-text="$t('vesting.locked')"/>
                            <div class="card-row__value">
                                <span v-if="lockedAmount === undefined" class="skeleton">10000 TON</span>
                                <template v-else>
                                    {{$ton(lockedAmount)}} TON
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('vesting.duration')"/>
                    <div class="card-row__value card-row__value--flex-row">
                        <div class="card-row">
                            <div class="card-row__name" v-text="$t('vesting.total')"/>
                            <div class="card-row__value">
                                <span v-if="vestingDuration === undefined" class="skeleton">100 Days</span>
                                <ui-date-period v-bind:seconds="vestingDuration"/>
                            </div>
                        </div>
                        <div class="card-row">
                            <div class="card-row__name" v-text="$t('vesting.cliff_period')"/>
                            <div class="card-row__value">
                                <span v-if="cliffDuration === undefined" class="skeleton">10 Days</span>
                                <ui-date-period v-bind:seconds="cliffDuration"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('vesting.unlock')"/>
                    <div class="card-row__value">
                        <span v-if="unlockPeriod === undefined" class="skeleton">10 Days</span>
                        <i18n v-else path="vesting.unlock_text">
                            <template v-slot:period>
                                <ui-date-period v-bind:seconds="unlockPeriod"/>
                            </template>
                        </i18n>
                    </div>
                </div>
            </div>
        </div>

        <ui-modal class="qr-modal" v-bind:isOpen.sync="qrModalVisible">
            <ui-qr show-logo v-bind:value="`ton://transfer/${addressCanonical}`" v-bind:size="300"/>
        </ui-modal>

        <vesting-tabs style="margin-top: 18px;"
            v-bind:address="addressCanonical"
            v-bind:sender="vestingSenderAddress"
            v-bind:whitelist="Object.values(whitelist)"
            v-bind:isActive="isActive"/>

        <div style="display: none">
            <a ref="devExplorerLink" target="_blank">View in Toncoin Explorer</a>
        </div>
    </section>
</template>

<script>
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import IconQr from '@img/icons/tonscan/qr-14.svg?vue';
import UiQr from '~/components/UiQr.vue';
import { showToast } from '~/toast.js';
import { getAddressInfo, getVestingData } from '~/api';
import { canonizeAddress } from '~/tonweb.js';
import { goToDevExplorerMixin } from '~/mixins';
import VestingTabs from './VestingTabs.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            addressCanonical: undefined,
            qrModalVisible: false,
            wallet: {},
            ownerAddress: undefined,
            totalVested: undefined,
            lockedAmount: undefined,
            startTime: undefined,
            vestingAvailable: undefined,
            vestingDuration: undefined,
            cliffDuration: undefined,
            unlockPeriod: undefined,
            vestingSenderAddress: undefined,
            whitelist: [],
        };
    },

    computed: {
        isActive() {
            if (this.wallet.is_active === undefined) {
                return undefined; // keep loading state
            }

            return this.wallet.is_active;
        },

        vestingLink() {
            return `https://vesting.ton.org/#${this.address}`;
        },
    },

    watch: {
        $route: 'loadData',
        address: {
            immediate: true,
            handler: 'canonizeAddress',
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        canonizeAddress() {
            this.addressCanonical = canonizeAddress(this.address);
        },

        async loadData() {
            const [walletInfo, { vesting_wallet: vestingInfo }] = await Promise.all([
                getAddressInfo(this.address),
                getVestingData(this.address).catch((error) => {
                    if (error.response.status === 400 || error.response.status === 500) {
                        showToast(this.$t('vesting.error.redirect_invalid'));
                        this.$router.push(this.$localizeRoute({
                            name: 'address',
                            params: { address: this.address },
                        }));
                    }
                }),
            ]);

            this.wallet = walletInfo;

            this.ownerAddress = vestingInfo.owner_address;
            this.totalVested = vestingInfo.vesting_total_amount;
            this.lockedAmount = vestingInfo.locked_amount;
            this.startTime = vestingInfo.vesting_start_time;
            this.vestingAvailable = vestingInfo.vesting_total_amount - vestingInfo.locked_amount;
            this.vestingDuration = vestingInfo.vesting_total_duration;
            this.cliffDuration = vestingInfo.cliff_duration;
            this.unlockPeriod = vestingInfo.unlock_period;
            this.vestingSenderAddress = vestingInfo.vesting_sender_address;
            this.whitelist = vestingInfo.whitelist || [];

            const payload = {
                address: this.addressCanonical,
                name: 'Vesting wallet',
                type: 'vesting',
            };
            await this.$store.dispatch('presaveAddressbookItem', payload);
        },
    },

    components: {
        VestingTabs, IconTitleHelp, UiQr, IconQr,
    },

    mixins: [goToDevExplorerMixin],
};
</script>
