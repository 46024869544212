<template>
    <div class="tx-history-wrap">
        <table class="ui-table">
            <thead>
                <tr>
                    <th style="max-width: none;">{{ $t('common.address') }}</th>
                </tr>
            </thead>
            <template v-if="sender">
                <tbody>
                    <tr>
                        <td style="max-width: none;">
                            <ui-address v-bind:address="sender" />
                            <div class="vesting-sender">{{ $t('address.all_tx_table.sender') }}</div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-for="item in whitelist" v-bind:key="`whitelist_${item}`">
                    <tr>
                        <td style="max-width: none;"><ui-address v-bind:address="item" /></td>
                    </tr>
                </tbody>
            </template>
            <template v-else>
                <tbody v-for="i in 8" v-bind:key="`whitelist_skeleton_${i}`">
                    <td v-pre>
                        <span class="tx-table-cell-skeleton" style="width: 100%;"></span>
                    </td>
                </tbody>
            </template>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        sender: String,
        whitelist: Array,
    },
};
</script>

<style lang="scss">
.vesting-sender {
    display: inline-block;
    background: var(--blue-bright-background);
    color: var(--blue-bright);
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
    padding: 1px 8px;
    margin: -10px 0 -10px 6px;
    transform: translateY(-2px);
    height: 16px;
    font-size: 10px;
    line-height: 17px;
    user-select: none;
}
</style>
