<template>
    <section>
        <div class="card-row">
            <div class="card-row__name" v-text="$t('tx.trace_id')" />
            <div class="card-row__value">
                <ui-copy-button v-if="traceId" v-bind:copy="traceId"
                    v-bind:successMessage="$t('tx.hash_copy_success')">
                    {{ traceId }}
                </ui-copy-button>
                <span v-else class="skeleton">0000000000000</span>
            </div>
        </div>

        <div class="card-row">
            <div class="card-row__name" v-text="$t('tx.timeStart')" />
            <div class="card-row__value">
                <template v-if="timeStart">
                    {{ formattedDate }}
                    <span class="card-timeago">&mdash;</span>
                    <ui-timeago class="card-timeago" v-bind:timestamp="timeStart" />
                </template>
                <span v-else class="skeleton">00/00/2000 10:00</span>
            </div>
        </div>

        <!-- <div class="card-row">
            <div class="card-row__name">LT</div>
            <div class="card-row__value">
                <ui-copy-button v-if="traceLT" v-bind:copy="traceLT"
                    v-bind:successMessage="$t('tx.hash_copy_success')">
                    {{ traceLT }}
                </ui-copy-button>
                <span v-else class="skeleton">0000000000000</span>
            </div>
        </div> -->
    </section>
</template>

<script>
import { formatTimestamp } from '../../../helpers.js';

export default {
    props: {
        traceId: String,
        timeStart: Number,
    },

    computed: {
        formattedDate() {
            return formatTimestamp(this.timeStart);
        },
    },
};
</script>
