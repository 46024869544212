<script>
import IconHistory from '@img/icons/material-duotone/history.svg?inline';
import IconContract from '@img/icons/material-duotone/data-object.svg?inline';
import UiTabs from '~/components/UiTabs.vue';
import TabContractSources from '~/components/address/Verifier/Verifier.vue';
import TxRoot from '~/components/address/TxRoot.vue';

export default {
    props: {
        isActive: Boolean,
        supply: String,
        decimals: Number,
        symbol: String,
        address: {
            type: String,
            required: true,
        },
    },

    render(createComponent) {
        return createComponent(UiTabs, {
            class: 'card card--tabbed',
            props: {
                tabs: this.tabs,
                changeUrlHash: true,
            },
            on: this.$listeners, // passthru listeners from parent
            scopedSlots: this.$scopedSlots,
        });
    },

    computed: {
        tabs() {
            const props = { address: this.address };
            const key = this.address; // keepalive key

            return [{
                key: 'events',
                text: this.$t('address.tab_events'),
                icon: IconHistory,
                content: {
                    key, props,
                    eager: true,
                    component: TxRoot,
                },
            },
            // {
            //     key: 'holders',
            //     text: this.$t('jetton.top_holders'),
            //     icon: IconToken,
            //     content: {
            //         key,
            //         component: JettonTabHolders,
            //         props: {
            //             address: this.address,
            //             decimals: this.decimals,
            //             supply: this.supply,
            //             symbol: this.symbol,
            //         },
            //     },
            // },
            {
                key: 'source',
                text: this.$t('address.tab_contract'),
                icon: IconContract,
                content: {
                    key,
                    component: TabContractSources,
                    props: {
                        address: this.address,
                        isActive: true,
                    },
                },
            }];
        },
    },
};
</script>
