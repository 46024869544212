<template>
    <div>
        <template v-if="[
            'jetton:burn',
            'jetton:burn_notification',
            'jetton:transfer',
            'jetton:transfer_notification',
            'jetton:internal_transfer',
            'jetton:swap',
            'jetton:route_tonapi',
        ].includes(action.type)">
            <div class="mobile-swap mobile-swap--red" v-if="action.type === 'jetton:swap'">
                <template v-if="meta.symbol_in !== 'TON'">
                    <span>
                        &minus; {{$ton(meta.amount_in, meta.decimals_in, true)}}
                    </span>
                    <ui-link class="mobile-swap--red" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_in_address }}">
                        {{meta.symbol_in}}
                    </ui-link>
                </template>
                <template v-else>
                    &minus; {{$ton(meta.amount_in, meta.decimals_in, true)}}
                    {{meta.symbol_in}}
                </template>
            </div>

            <ui-inline-jetton v-else
                v-bind:isOut="is_out"
                v-bind:address="meta.jetton_address"
                v-bind:value="action.amount"
                v-bind:imageData="meta.image_data"
                v-bind="meta.jetton"/>
        </template>

        <!-- incoming NFT transfer: -->
        <ui-inline-nft-item
            v-else-if="action.type === 'nft:ownership_assigned'"
            v-bind:address="from" v-bind:event-type="event"/>

        <!-- outgoing NFT transfer: -->
        <ui-inline-nft-item
            v-else-if="action.type === 'nft:transfer'"
            v-bind:address="to" v-bind:event-type="event"/>

        <!-- NFT transfer from tonapi: -->
        <ui-inline-nft-item
            v-else-if="action.type === 'nft:transfer_tonapi'"
            v-bind:address="action.nft" v-bind:event-type="event"/>

        <!-- nominator pool actions: -->
        <template
            v-else-if="action.type.startsWith('pool:')">
            <template v-if="action.type === 'pool:new_stake_ok'">
                <span class="tx-row-msg-action tx-row-msg-action--single">
                    Stake accepted
                </span>
            </template>

            <template v-else>
                {{$ton(amount, undefined, true)}} TON
            </template>
        </template>

        <span v-else class="muted">
            {{$ton(amount)}} TON
        </span>
    </div>
</template>

<script>
import UiInlineJetton from './TxRowInlineJetton.vue';
import UiInlineNftItem from './TxRowInlineNft.vue';

export default {
    props: {
        action: Object,
        amount: String,
        is_out: Boolean,
        meta: {
            type: Object,
            default: () => ({}),
        },
        from: String,
        to: String,
        event: String,
    },
    components: {
        UiInlineJetton, UiInlineNftItem,
    },
};
</script>

<style lang="scss">
.mobile-swap {
    text-transform: uppercase;

    &--green {
        color: var(--badge-green-color);
    }
    &--red {
        color: var(--badge-red-color);
    }
}
</style>
