var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[([
        'jetton:burn',
        'jetton:burn_notification',
        'jetton:transfer',
        'jetton:transfer_notification',
        'jetton:internal_transfer',
        'jetton:swap',
        'jetton:route_tonapi',
    ].includes(_vm.action.type))?[(_vm.action.type === 'jetton:swap')?_c('div',{staticClass:"mobile-swap mobile-swap--red"},[(_vm.meta.symbol_in !== 'TON')?[_c('span',[_vm._v("\n                    − "+_vm._s(_vm.$ton(_vm.meta.amount_in, _vm.meta.decimals_in, true))+"\n                ")]),_vm._v(" "),_c('ui-link',{staticClass:"mobile-swap--red",attrs:{"to":{ name: 'jetton', params: { address: _vm.meta.jetton_in_address }}}},[_vm._v("\n                    "+_vm._s(_vm.meta.symbol_in)+"\n                ")])]:[_vm._v("\n                − "+_vm._s(_vm.$ton(_vm.meta.amount_in, _vm.meta.decimals_in, true))+"\n                "+_vm._s(_vm.meta.symbol_in)+"\n            ")]],2):_c('ui-inline-jetton',_vm._b({attrs:{"isOut":_vm.is_out,"address":_vm.meta.jetton_address,"value":_vm.action.amount,"imageData":_vm.meta.image_data}},'ui-inline-jetton',_vm.meta.jetton,false))]:(_vm.action.type === 'nft:ownership_assigned')?_c('ui-inline-nft-item',{attrs:{"address":_vm.from,"event-type":_vm.event}}):(_vm.action.type === 'nft:transfer')?_c('ui-inline-nft-item',{attrs:{"address":_vm.to,"event-type":_vm.event}}):(_vm.action.type === 'nft:transfer_tonapi')?_c('ui-inline-nft-item',{attrs:{"address":_vm.action.nft,"event-type":_vm.event}}):(_vm.action.type.startsWith('pool:'))?[(_vm.action.type === 'pool:new_stake_ok')?[_c('span',{staticClass:"tx-row-msg-action tx-row-msg-action--single"},[_vm._v("\n                Stake accepted\n            ")])]:[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.amount, undefined, true))+" TON\n        ")]]:_c('span',{staticClass:"muted"},[_vm._v("\n        "+_vm._s(_vm.$ton(_vm.amount))+" TON\n    ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }