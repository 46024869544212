<template>
    <div class="card" style="height: 100%;max-width: 100%;">
        <div class="card-title" style="border: none;" v-text="$t('stats.transaction_count')"/>

        <bar-chart ref="chart" style="width: calc(100% - 10px); padding-left: 10px"
            v-bind:labels="parsedChartLabels"
            v-bind:datasets="datasets"
            hide-legend />
    </div>
</template>

<script>
import { getTxCount } from '~/api/tontech.js';
import BarChart from '~/lib/Lightchart/UiChartBar.vue';

export default {
    data() {
        return {
            labels: undefined,
            datasets: undefined,
        };
    },

    mounted() {
        this.getData();
    },

    computed: {
        parsedChartLabels() {
            if (!this.labels) return undefined;
            return this.labels;
        },
    },

    methods: {
        async getData() {
            try {
                const data = await getTxCount().then(list => list.slice(-52));

                const dataset = Object.freeze({
                    data: data.map(period => period.transactions_count),
                    label: this.$t('stats.transaction_count'),
                    suffix: '',
                });

                this.labels = data.map(({ date }) => new Date(date));
                this.datasets = [dataset];
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        },
    },

    components: {
        BarChart,
    },
};
</script>
