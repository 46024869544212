<script>
import IconList from '@img/icons/material-duotone/list.svg?inline';
import IconContract from '@img/icons/material-duotone/data-object.svg?inline';
import IconToken from '@img/icons/material-duotone/toll.svg?inline';
// import IconTrade from '@img/icons/tonscan/trade.svg?inline';
import IconHistory from '@img/icons/material-duotone/history.svg?inline';
import UiTabs from '~/components/UiTabs.vue';
import TabContractSources from '~/components/address/Verifier/Verifier.vue';
import TxHistory from '~/components/address/TxHistory.vue';
import JettonTabHistory from '~/components/jetton/JettonTabHistory.vue';
import JettonTabHolders from './JettonTabHolders.vue';
// import JettonTabTrading from './JettonTabTrading.vue';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        dyorId: {
            type: Number,
            default: undefined,
        },
        supply: {
            type: Number,
            default: 0,
        },
        decimals: {
            type: Number,
            default: 0,
        },
        symbol: {
            type: String,
            default: '',
        },
        address: {
            type: String,
            required: true,
        },
    },

    render(createElement) {
        return createElement(UiTabs, {
            class: 'card card--tabbed',
            props: {
                tabs: this.tabs,
                changeUrlHash: true,
            },
            scopedSlots: this.$scopedSlots,
        });
    },

    computed: {
        tabs() {
            return [
                this.createTab('swaps', this.$t('address.tab_swaps'), IconHistory, JettonTabHistory, {
                    address: this.address,
                    dyorId: this.dyorId,
                }, true),
                // this.createTab('trading', this.$t('address.tab_trading'), IconTrade, JettonTabTrading, {
                //     address: this.address,
                // }, true),
                this.createTab('transactions', this.$t('address.tab_transactions'), IconList, TxHistory, {
                    address: this.address,
                }, false),
                this.createTab('holders', this.$t('jetton.top_holders'), IconToken, JettonTabHolders, {
                    address: this.address,
                    decimals: this.decimals,
                    supply: this.supply,
                    symbol: this.symbol,
                }, false),
                this.createTab('source', this.$t('address.tab_contract'), IconContract, TabContractSources, {
                    address: this.address,
                    isActive: true,
                }, false),
            ];
        },
    },

    methods: {
        createTab(key, text, icon, component, props, eager = false) {
            return {
                key,
                text,
                icon,
                content: {
                    key: this.address,
                    component,
                    props,
                    eager,
                },
            };
        },
    },
};
</script>
