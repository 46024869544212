<template>
    <div class="card" style="margin-top: 0">
        <div class="tx-history-wrap desktop-table">
            <div class="card-title">
                <ui-link class="card-title__icon" v-bind:to="{ name: 'blocks' }">
                    <icon-back fill="currentColor"/>
                </ui-link>

                <template v-if="workchain === -1">
                    {{$t('blocks.last_masterchain_blocks_title')}}
                </template>

                <template v-else>
                    {{$t('blocks.last_workchain_blocks_title')}}
                </template>
            </div>

            <table class="ui-table">
                <thead>
                    <tr>
                        <th v-text="$t('blocks.block')"/>
                        <th v-text="$t('blocks.transactions')"/>
                        <th v-if="!isMobile" v-text="$t('tx.hash')"/>
                        <th v-text="$t('common.time')"/>
                    </tr>
                </thead>

                <tbody v-if="blocks.length > 0">
                    <tr class="new-row-animation"
                        v-for="block in blocks"
                        v-bind:key="`${block.workchain}:${block.seqno}`">
                        <td>
                            <ui-link v-bind:to="{ name: 'block', params: { ...block }}">
                                {{block.seqno}}
                            </ui-link>
                        </td>
                        <td>
                            <span style="padding-left: 36px;">
                                {{ block.tx_count }}
                            </span>
                        </td>
                        <td v-if="!isMobile" v-text="block.root_hash_hex"/>
                        <td>
                            <ui-datetime hide-date v-bind:timestamp="block.gen_utime"/>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr v-for="i of 40" v-bind:key="`page_blocks_workchain_blocks_skeleton_${i}`">
                        <td>
                            <span class="skeleton">123456789</span>
                        </td>
                        <td>
                            <span class="skeleton">9999</span>
                        </td>
                        <td v-if="!isMobile">
                            <span class="skeleton">NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN</span>
                        </td>
                        <td>
                            <span class="skeleton">00:00:00 AM</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ui-mugen-scroll show-button
                v-bind:handler="loadMore"
                v-bind:shouldHandle="shouldHandleScroll"
                v-bind:isLoading="isLoading"/>
        </div>
    </div>
</template>

<script>
import { getPreviousBlocks } from '~/api';
import IconBack from '@primer/octicons/build/svg/arrow-left-24.svg?vue';

export default {
    props: {
        workchain: {
            type: [Number, String],
            required: true,
        },
    },

    data() {
        return {
            blocks: [],
            limit: 64,
            isLoading: false,
            truncateBlockList: true,
            timerHandle: null,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading;
        },
    },

    async mounted() {
        await this.fetchBlocks();
        this.timerHandle = setInterval(this.loadLatestBlock, 2800);
    },

    beforeDestroy() {
        clearInterval(this.timerHandle);
    },

    methods: {
        async fetchBlocks(additionalParams = {}) {
            const params = {
                wc: this.workchain,
                limit: this.limit,
                ...additionalParams,
            };
            return getPreviousBlocks(params);
        },

        async loadLatestBlock() {
            const newBlocks = await this.fetchBlocks({
                afterLt: this.blocks.at(-1)?.gen_utime,
            });

            if (newBlocks.length > 0) {
                this.blocks.unshift(...newBlocks);

                if (this.truncateBlockList) {
                    this.blocks.splice(this.limit);
                }
            }
        },

        async loadMore() {
            this.truncateBlockList = false;
            this.isLoading = true;

            const lastGenUtime = this.blocks.length > 0 ? this.blocks.at(-1).gen_utime - 1 : undefined;
            const newBlocks = await this.fetchBlocks({ endUtime: lastGenUtime });

            this.blocks = this.blocks.concat(newBlocks);
            this.isLoading = false;
        },
    },

    components: {
        IconBack,
    },
};
</script>

<style lang="scss">
.last-blocks-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }

    tr:last-child > td {
        border-bottom: 1px solid var(--card-border-color);
    }
}
</style>
