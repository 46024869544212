<template functional>
    <tbody>
        <tr class="new-row-animation">
            <td class="tx-mobile-flex-content" colspan="10" style="font-size: 13px;">
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--service" v-bind:to="{
                    name: 'tx',
                    params: { hash: props.hash },
                }">
                    <icon-out/>
                </ui-link>

                <div class="tx-mobile-content">
                    <div class="tx-block-mobile-content__wrapper">
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__left">
                                <ui-address v-bind:address="props.account"/>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                {{ props.lt }}
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__left">
                                <ui-address v-bind:address="props.hash" v-bind:disabled="true"/>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                {{ props.seqno }}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>

export default {
    props: {
        seqno: Number,
        hash: String,
        account: String,
        lt: String,
    },

};
</script>

<style lang="scss">
.tx-block-mobile-content {
    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        white-space: nowrap;
    }

    &__left {
        flex-shrink: 1;
        padding-right: 8px;
    }

    &__right {
        flex-shrink: 0;
        text-align: right;
        padding-left: 8px;
    }

    &__left, &__right {
        display: inline-flex;
        min-width: 0;
    }
}
</style>
