<script>
export default {
    render(createElement) {
        return createElement('img', { attrs: { src: this.src } });
    },

    props: {
        animationUrl: {
            type: String,
            required: true,
        },
        fallbackUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            src: this.fallbackUrl,
        };
    },

    async mounted() {
        let image = new Image();
        image.src = this.animationUrl;

        try {
            await image.decode();
            this.src = image.src;
        } catch {
            // couldn't load image, do nothing
        } finally {
            image = undefined;
        }
    },
};
</script>
