<template>
    <div class="card-market-box">
        <div class="card-featured-jetton">
            <div style="display: flex">
                <div class="card-featured-jetton__image">
                    <ui-round-image size="tiny" class="card-featured-jetton__image" v-bind:src="image" />
                </div>
                <div class="card-featured-info">
                    <div style="font-weight: 500">{{ name }}</div>
                    <div class="muted">{{ symbol }}</div>
                </div>
            </div>
            <span v-if="price">${{ formattedPriceChart(price) }}</span>
            <span class="skeleton" style="height: 24px" v-else>$2847.11</span>
        </div>
        <div class="card-market-body chart-featured-jettons--overlay" v-on:click="handleChartClick">
            <mini-chart class="chart-featured-jettons" v-bind:labels="labels" v-bind:datasets="datasets" />
        </div>
    </div>
</template>

<script>
import MiniChart from '~/lib/Lightchart/UiChartMini.vue';
import { formattedPriceChart } from '~/helpers';

export default {
    props: [
        'labels',
        'datasets',
        'price',
        'address',
        'name',
        'symbol',
        'image',
        'show',
    ],

    methods: {
        formattedPriceChart,
        handleChartClick() {
            this.$router.push({
                name: 'jetton',
                params: {
                    address: this.address,
                },
            });
        },
    },

    components: {
        MiniChart,
    },
};
</script>

<style lang="scss" scoped>
.card-featured-jetton {
    margin-top: 30px;
    padding: 12px 16px 0 16px;
    display: flex;
    justify-content: space-between;
    user-select: none;

    font-size: 20px;

    &__image {
        width: 32px;
        height: 32px;
    }
}

.chart-featured-jettons {
    margin-top: -8px;
    pointer-events: none;

    &--overlay {
        cursor: pointer;
    }
}

.card-featured-info {
    display: flex;
    font-size: 14px;
    margin-left: 10px;
    flex-direction: column;
}
</style>
