<template>
    <div class="filter filter-hidden">
        <filter-button
            v-on:open-menu-request="openMenu = $event"
            v-bind:openMenu="this.openMenu"
            v-bind:toggle-menu="toggleMenu"
        />
        <filter-menu
            v-on:open-menu-request="openMenu = $event"
            v-bind:openMenu="this.openMenu"
            v-bind:toggle-menu="toggleMenu"
        />
        <div
            class="filter-overlay"
            v-if="this.openMenu"
            v-on:click="toggleMenu"
        ></div>
    </div>
</template>

<script>
import FilterButton from './FilterButton/FilterButton.vue';
import FilterMenu from './FilterMenu/FilterMenu.vue';

export default {
    data() {
        return {
            openMenu: false,
            scrollPosition: 0,
        };
    },

    methods: {
        toggleMenu() {
            this.openMenu = !this.openMenu;
            this.$store.commit('updateFilterVisible', !this.openMenu);
            if (this.isMobile && this.openMenu === true) {
                this.scrollPosition = window.scrollY;
                document.body.classList.add('no-scroll-body');
                document.body.style.top = `-${this.scrollPosition}px`;
            } else {
                document.body.classList.remove('no-scroll-body');
                document.body.style.top = '';
                window.scrollTo(0, this.scrollPosition);
            }
        },
    },

    components: {
        FilterButton,
        FilterMenu,
    },
};
</script>

<style lang="scss">
.filter {
    padding: 0 10px;
    position: relative;
    z-index: 1020;
}

// .filter-hidden {
//     display: none;
// }

.filter-button {
    cursor: pointer;
    position: relative;
}

.filter:before {
    content: "";
    height: 100%;
    width: 80px;
    position: absolute;
    left: -75px;
    top: 0;
    background: linear-gradient(to left, var(--card-background), transparent);
    pointer-events: none;
}

.filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90000;
}

.no-scroll-body {
    position: fixed;
    width: 100%;
    overflow: hidden;
}

@media screen and (max-width: 599px) {
    .filter-overlay {
        background: rgba(0,0,0,0.4);
    }
}
</style>
