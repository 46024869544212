<template>
    <div class="widget">
        <div class="widget-content widget-channel-container" v-if="newsData.length > 0">
            <news-feed-item v-for="(news, index) in newsData" v-bind:key="`news-id-${index}`" v-bind:id="news.id"
                v-bind:channelId="news.channelId" v-bind:channelUsername="news.channelUsername" v-bind:channelName="news.channelName" v-bind:text="news.text" v-bind:date="news.date" />
        </div>
        <div class="widget-content widget-channel-container" v-else>
            <news-feed-skeleton v-for="i in 4" v-bind:key="`news-skeleton-${i}`" />
        </div>
    </div>
</template>

<script>
import { getNews } from '~/api/news';
import NewsFeedItem from './NewsFeedItem.vue';
import NewsFeedSkeleton from './NewsFeedSkeleton.vue';

export default {
    data() {
        return {
            newsData: [],
        };
    },

    watch: {
        '$i18n.locale': function () {
            this.getNewsInfo();
        },
    },

    mounted() {
        this.getNewsInfo();
    },

    methods: {
        async getNewsInfo() {
            const lang = this.$i18n.locale;
            const limit = 24;
            this.newsData = [];

            const channels = {
                en: [1744725639, 1379327992, 1497424960, 1729567684, 1191264954, 1556132596],
                ru: [1433990798, 2020833003, 1539211564, 1444152677, 1562118593],
            };

            let response;

            try {
                response = await getNews(channels[lang], limit);
            } catch (e) {
                console.error(e);
            }

            const getChannelsData = response.channels;
            const getNewsData = response.posts;
            this.newsData = getNewsData.map((item) => {
                const channelInfo = getChannelsData[item.channel_id];
                return {
                    channelId: item.channel_id,
                    channelUsername: channelInfo.username,
                    channelName: channelInfo.title,
                    id: item.post_id,
                    date: item.date,
                    text: item.text,
                };
            });
        },
    },

    components: {
        NewsFeedItem,
        NewsFeedSkeleton,
    },
};
</script>

<style lang="scss">
.widget-channel-container {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
</style>
