<template>
    <a v-bind:href="link" class="widget-apps-item">
        <div class="widget-apps-img">
            <img v-bind:src="image" />
        </div>
        <div class="widget-apps-content">
            <div class="widget-apps-name">{{ name }}</div>
            <div class="widget-apps-description">{{ currentDescription }}</div>
        </div>
    </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        name: String,
        image: String,
        description_en: String,
        description_ru: String,
        link: String,
    },

    computed: {
        ...mapState({ appsLanguage: 'appLocale' }),
        currentDescription() {
            return this.$i18n.locale === 'ru' ? this.description_ru : this.description_en;
        },
    },
};
</script>
