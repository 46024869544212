<template>
    <section>
        <div v-if="traceEmpty" class="tx-history-empty-panel" v-text="$t('address.tx_table.empty')" />
        <div v-show="!traceEmpty" class="tx-history-wrap desktop-table">
            <table class="tx-table">
                <thead v-if="!isMobile">
                    <tr>
                        <th width="100">
                            <div class="tx-table__cell">{{ $t('transactionPage.actions') }}</div>
                        </th>
                        <th>
                            <div class="tx-table__cell">{{ $t('transactionPage.route') }}</div>
                        </th>
                        <th>
                            <div class="tx-table__cell">Payload</div>
                        </th>
                        <th class="column-wide">
                            <div class="tx-table__cell tx-table__cell--align-right">{{ $t('transactionPage.value') }}</div>
                        </th>
                    </tr>
                </thead>
                <template v-if="!isDataLoaded">
                    <component
                        v-for="i in 4"
                        v-bind:is="isMobile
                            ? 'event-overview-row-skeleton-mobile'
                            : 'event-overview-row-skeleton'"
                        v-bind:key="`tx_skeleton_${i}`"
                    />
                </template>
                <template v-else>
                    <component
                        v-for="(action, i) in traceActions"
                        v-bind:is="isMobile
                            ? 'event-overview-row-mobile'
                            : 'event-overview-row'"
                        v-bind:key="i"
                        v-bind:action="action"
                        v-bind:event="i === 0"
                        v-bind:actionDetails="getActionDetails(action)"
                        v-bind:type="action.type"/>
                </template>
            </table>
        </div>
    </section>
</template>

<script>
import EventOverviewRow from './EventOverviewRow.vue';
import EventOverviewRowMobile from './EventOverviewRowMobile.vue';
import EventOverviewRowSkeleton from './EventOverviewRowSkeleton.vue';
import EventOverviewRowSkeletonMobile from './EventOverviewRowSkeletonMobile.vue';

export default {
    data() {
        return {
            isDataLoaded: false,
        };
    },
    props: {
        traceActions: {
            type: [Array, undefined],
            default: () => undefined,
        },
        trace: Object,
        traceEmpty: Boolean,
    },

    methods: {
        getActionDetails(action) {
            const type = action.type;
            return action[type];
        },
    },

    watch: {
        traceActions: {
            handler(newVal) {
                this.isDataLoaded = !!newVal;
            },
            immediate: true,
        },
        // trace: {
        //     handler(newVal) {
        //         if (newVal) {
        //             this.traceData = newVal;
        //             console.log('trace data', this.traceData)
        //         }
        //     },
        // }
    },

    mounted() {
        if (this.traceActions) {
            this.isDataLoaded = true;
        }
    },

    components: {
        EventOverviewRow,
        EventOverviewRowMobile,
        EventOverviewRowSkeleton,
        EventOverviewRowSkeletonMobile,
    },
};
</script>
