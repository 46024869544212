<template>
    <section>
        <div v-if="isEmpty"
            class="tx-history-empty-panel"
            v-text="$t('nominator_pool.nominator_table_empty')"/>

        <!-- Showing plain text instead of the skeleton: -->
        <div v-else-if="isLoading"
            class="tx-history-empty-panel"
            v-text="$t('common.loading')"/>

        <div v-show="!isEmpty && !isLoading" class="tx-history-wrap">
            <table class="ui-table">
                <thead>
                    <tr>
                        <th v-text="$t('common.address')"/>
                        <th v-text="$t('nominator_pool.nominator_table_stake')" class="ui-table__cell--align-right"/>
                        <th v-text="$t('common.pie')"/>
                        <th v-text="$t('nominator_pool.nominator_table_pending')" class="ui-table__cell--align-right"/>
                        <th v-text="$t('nominator_pool.nominator_table_withdrawal')" class="ui-table__cell--align-right"/>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="nominator in nominators" v-bind:key="`pool_nominators_${address}_${nominator.address}`">
                        <td>
                            <ui-address
                                v-bind:address="nominator.address"
                                v-bind:alias="addressBook[nominator.address]?.alias"/>
                        </td>
                        <td class="ui-table__cell--align-right">
                            {{$ton(nominator.amount)}} TON
                        </td>
                        <td>
                            {{Math.round(nominator.weight * 100 * 1000) / 1000}}%
                        </td>
                        <td class="ui-table__cell--align-right">
                            {{$ton(nominator.pending_deposit_amount)}} TON
                        </td>
                        <td class="ui-table__cell--align-right">
                            {{nominator.withdraw_requested ? $t('common.yes') : $t('common.no')}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
import { getNominatorPoolNominators } from '~/api';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            nominators: [],
            isLoading: true,
            addressBook: {},
        };
    },

    computed: {
        isEmpty() {
            // If the data is loaded, but the list is empty, then there're no nominators:
            return !this.isLoading && this.nominators.length === 0;
        },
    },

    watch: {
        address: {
            immediate: true,
            handler(address) {
                if (address) {
                    this.nominators = [];
                    this.isLoading = true;

                    this.$nextTick(() => this.loadData());
                }
            },
        },
    },

    methods: {
        loadData() {
            getNominatorPoolNominators(this.address).then((info) => {
                this.nominators = info.nominators.map(Object.freeze);
                this.addressBook = Object.freeze(info['@addresses']);
            }).catch(console.error).then(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>
