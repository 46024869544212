<template>
    <search-result compact class="search-result-recent">
        <template v-slot:contents>
            <search-result-domain v-if="isDomain" v-bind:name="name" v-bind:addressType="addressType"/>

            <template v-else-if="name">
                {{name}}
            </template>

            <ui-address v-else disabled hide-name v-bind:address="address"/>
        </template>

        <template v-slot:buttons>
            <icon-trash class="search-result-recent__button"
                v-on:click.native.stop.prevent="$emit('delete')"/>
        </template>
    </search-result>
</template>

<script>
import IconTrash from '@primer/octicons/build/svg/trash-16.svg?vue';
import SearchResultDomain from './AppSearchResultDomain.vue';
import SearchResult from './AppSearchResult.vue';

export default {
    props: {
        addressType: String,
        address: String,
        name: String,
    },

    computed: {
        isDomain() {
            return [
                'telegram_username',
                'dns_domain',
            ].includes(this.addressType);
        },
    },

    components: {
        IconTrash, SearchResultDomain, SearchResult,
    },
};
</script>

<style lang="scss">
.search-result-recent__button {
    fill: var(--badge-grey-color);
    width: 16px;
    height: 16px;
    padding: 8px;
    margin: -8px;
    border-radius: 50%;
    &:hover {
        fill: var(--badge-red-color);
    }
}
</style>
