import * as Sentry from '@sentry/vue';
import { NEWS_API_ENDPOINT, SENTRY_DSN, SENTRY_REPLAYS_SAMPLE_RATE, SENTRY_TRACES_SAMPLE_RATE } from '~/config';

const sentryConfig = {
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: [
        NEWS_API_ENDPOINT,
        // EXTENDER_CONTRACTS_API_ENDPOINT,
    ],
    replaysSessionSampleRate: SENTRY_REPLAYS_SAMPLE_RATE,
    replaysOnErrorSampleRate: 1.0,
};

export default function registerSentry(Vue, router) {
    if (SENTRY_DSN) {
        Sentry.init({
            Vue,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
            ],
            ...sentryConfig,
        });
    }
}
