<template>
    <section>
        <div class="verifier-admonition">
            <lightbulbIcon class="verifier-admonition__icon" />

            <div class="verifier-admonition__right">
                <h2 class="verifier-admonition__title">
                    {{ $t('address.contract.admonition.versions_title') }}
                </h2>

                <div class="verifier-admonition__text">
                    {{ $t('address.contract.admonition.versions_text') }}
                </div>
            </div>
        </div>

        <div class="tx-history-wrap desktop-table">
            <table class="ui-table">
                <thead>
                    <tr>
                        <th>{{ $t('address.contract.wallet_version') }}</th>
                        <th>{{ $t('common.address') }}</th>
                        <th>{{ $t('address.info.balance') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(key, index) in addressVersionsList" v-bind:key="key">
                        <td>
                            wallet {{ formatKey(index) }}
                        </td>
                        <td>
                            <ui-address v-bind:address="nonbounceableAddress(addressVersionsList[index])" />
                        </td>
                        <td>
                            <span v-if="!balances" class="skeleton">balance</span>
                            <template v-else>
                                <span>{{ $ton(balances[addressVersionsList[index]] || 0) }} TON</span>
                                <span class="muted">
                                    ≈ <ui-fiat v-bind:tonValue="balances[addressVersionsList[index]]"/>
                                </span>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
import lightbulbIcon from '@img/icons/material-duotone/lightbulb.svg?vue';
import { getPackOfBalances } from '~/api/toncenterV3';
import { canonizeAddress } from '~/tonweb';

export default {
    props: ['addressVersionsList'],

    data() {
        return {
            balances: undefined,
        };
    },

    mounted() {
        this.getListOfBalances();
    },

    methods: {
        nonbounceableAddress(address) {
            return canonizeAddress(address, { type: 'wallet' });
        },
        async getListOfBalances() {
            const addresses = Object.values(this.addressVersionsList);
            this.balances = await getPackOfBalances(addresses);
        },
        formatKey(key) {
            const match = key.match(/wallet_v(\d+)(?:_r(\d+))?(?:_(\w+))?/);
            if (match) {
                const version = match[1];
                const revision = match[2] ? `r${match[2]}` : '';
                const label = match[3] ? ` ${match[3]}` : '';
                return `v${version} ${revision}${label}`;
            }

            return key;
        },
    },

    components: {
        lightbulbIcon,
    },
};
</script>

<style scoped lang="scss">

</style>
