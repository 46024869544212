import { JETTON_ENDPOINT } from '~/config.js';

import axios from 'axios';
import { formatDecimals, formatDecimalsUSDT } from '../helpers';

const http = axios.create({
    baseURL: JETTON_ENDPOINT,
});

export const getJettons = async (params) => {
    try {
        const { data: response } = await http.get('/jettons', { params });

        const jettons = response.data.jettons.map((jetton) => {
            const price_usd_raw = parseFloat(jetton.priceUsd);
            const market_cap_raw = parseFloat(jetton.marketCap);
            const volume_raw = parseFloat(jetton.volume24h);

            const price_usd = jetton.id === 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'
                ? formatDecimalsUSDT(price_usd_raw)
                : formatDecimals(price_usd_raw, 9);

            return Object.freeze({
                id: jetton.id,
                jetton: jetton.address,
                name: jetton.name,
                symbol: jetton.symbol,
                img: jetton.image,
                price: price_usd,
                decimals: jetton.decimals,
                volume: volume_raw,
                market_cap: market_cap_raw,
                chartLast7d: jetton.chartLast7d,
                price_changes: {
                    hour: jetton.priceChange1h,
                    day: jetton.priceChange24h,
                    week: jetton.priceChange7d,
                },
            });
        });

        return jettons;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getJettonPool = async (address) => {
    try {
        const params = { address };
        const { data: response } = await http.get('/jetton/pool', { params });

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getJetton = async (address) => {
    try {
        const params = { address };
        const { data: response } = await http.get('/jettons/jetton', { params });

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};
