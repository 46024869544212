<template>
    <div class="card market-info-block">
        <div class="card-market-box">
            <div class="card-market-header">
                <div class="card-market-title">
                    <span>{{ $t('market_blocks.headers.tips') }}</span>
                </div>
            </div>
            <div class="card-market-body">
                <div class="card-market-tips-text">
                    <p v-html="$t('market_blocks.tips')" />
                </div>
                <div class="card-market-tips-button">
                    <a v-bind:href="$t('market_blocks.learn_more_link')" target="_blank" class="button">
                        <span>{{ $t('market_blocks.learn_more') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
};
</script>

<style lang="scss">
.card-market-body {
    width: 100%;
}

.card-market-tips-text {
    padding: 0 16px;
    line-height: 1.9em;
    white-space: break-spaces;

    b {
        font-weight: 500;
        display: inline;
    }
}

.card-market-tips-text p {
    margin-bottom: 10px;
}

.card-market-tips-button {
    padding: 0 16px 10px 16px;
}

.card-market-tips-button .button {
    background-color: var(--blue-bright-background);
    border-radius: 0.5rem;
    color: var(--blue-bright);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 14px;
    text-transform: uppercase;
    transition: .2s;
    display: block;
    text-align: center;
}

.card-market-tips-button .button:hover {
    text-decoration: none;
}

@media screen and (max-width: 599px) {

}
</style>
