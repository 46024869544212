<template>
    <div class="jetton-stats">
        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('address.info.address') }}</div>
            <div class="jetton-stats-row__value">
                <ui-copy-button class="jetton-copy-items-inner" v-bind:successMessage="$t('address.info.copy_success')" v-bind:copy="address">
                    <span v-if="addressMeta.isScam" class="card-main-address-badge card-main-address-badge--scam">SCAM</span>
                    <div class="jetton-owner" style="max-width: 180px; margin-right: 5px;">
                        <ui-address v-bind:address="address" v-bind:disabled="true" v-bind:hide-name="true" />
                    </div>
                    <!-- <span>{{ truncatedAddress }}</span> -->
                    <!-- <ui-address style="max-width: 130px;" :address="address" :hideName="true" /> -->
                </ui-copy-button>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.admin_address') }}</div>
            <div class="jetton-stats-row__value jetton-owner">
                <span v-if="admin_address === undefined" class="skeleton">Ef8zMzMzMzMzM</span>
                <span v-else-if="!admin_address">
                    {{ $t('jetton.admin_address_empty') }}
                </span>
                <ui-address v-else v-bind:address="userFriendlyAdminAddress" />
            </div>
        </div>

        <!-- <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.mutable') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="is_mutable !== undefined">
                    {{ is_mutable ? $t('common.yes') : $t('common.no') }}
                </span>
                <span v-else class="skeleton skeleton--inline">Maybe</span>
            </div>
        </div> -->

        <div class="jetton-stats-row" v-if="metadata_url">
            <div class="jetton-stats-row__name">{{ $t('jetton.metadata_source') }}</div>
            <div class="jetton-stats-row__value">
                <ui-metadata-source v-bind:url="metadata_url" v-bind:loader="getRawMetadata" />
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.balance') }}</div>
            <div class="jetton-stats-row__value" v-if="balance !== undefined">
                <span>{{ $ton(balance) }}</span> TON
                <template v-if="balance !== '0'">
                    <span v-if="$store.state.exchangeRate" style="color: #717579">
                        ≈ <ui-fiat v-bind:tonValue="balance" />
                    </span>
                </template>
            </div>
            <div v-else class="jetton-stats-row__value">
                <span class="skeleton">00000 TON ≈ 00000 USD</span>
            </div>
        </div>
    </div>
</template>

<script>
import UiMetadataSource from '~/components/UiMetadataSource.vue';
import { toUserFriendlyAddress } from '@tonconnect/sdk';

export default {
    props: {
        address: String,
        admin_address: String,
        is_mutable: Boolean,
        metadata_url: String,
        balance: Number,
    },

    computed: {
        addressMeta() {
            return this.$store.getters.getAddressMeta(this.address);
        },
        truncatedAddress() {
            return this.truncateAddress(this.address);
        },
        userFriendlyAdminAddress() {
            return toUserFriendlyAddress(this.admin_address);
        },
    },

    methods: {
        // getRawMetadata() {
        //     return getJettonRawMetadata(this.address);
        // },
        truncateAddress(address) {
            if (address.length > 8) {
                const start = address.substring(0, 4);
                const end = address.substring(address.length - 8);
                return `${start}....${end}`;
            }
            return address;
        },
    },

    components: {
        UiMetadataSource,
    },
};
</script>

<style lang="scss">
.jetton-owner a {
    font-weight: 400!important;
}

.jetton-owner a:hover {
    text-decoration: underline;
}

.jetton-copy-items-inner {
    display: flex;
    align-items: center;
}

.jetton-owner {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    width: 100%;
    white-space: nowrap;
    text-align: right;
}
</style>
