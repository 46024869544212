var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item.image.type !== 'NftContentNotLoaded')?_c('ui-link',{staticClass:"user-nft",attrs:{"to":{
        name: _vm.item.kind === 'DnsItem' ? 'address' : 'nft',
        params: {
            address: _vm.item.address,
            skeletonHint: 'item',
        },
    }}},[_c('div',{staticClass:"user-nft__image"},[(_vm.item.image)?[(_vm.item.image.type === 'NftContentImage' && _vm.item.image.thumb && _vm.item.image.thumb.sized && !_vm.imageError)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":_vm.item.image.thumb.sized,"loading":_vm.idx > 4 ? 'lazy' : 'eager',"alt":_vm.item.name},on:{"error":function($event){_vm.imageError = true}}}):(_vm.imageError)?_c('div',{staticClass:"user-nft__image__broken"},[_c('icon-broken')],1):(_vm.item.image.type === 'NftContentLottie' && _vm.item.image.preview && _vm.item.image.preview.sized)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":_vm.item.image.preview.sized,"loading":_vm.idx > 4 ? 'lazy' : 'eager',"alt":_vm.item.name}}):(_vm.item.image.type === 'NftContentVideo' && _vm.item.image.cover)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":_vm.item.image.cover,"loading":_vm.idx > 4 ? 'lazy' : 'eager',"alt":_vm.item.name}}):_vm._e()]:_vm._e()],2),_vm._v(" "),_vm._m(0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"user-nft__footer"},[(_vm.item.collection)?_c('ui-link',{staticClass:"user-nft__collection",attrs:{"to":{
                name: 'nft',
                params: {
                    address: _vm.item.collection.address,
                    skeletonHint: 'collection',
                },
            }}},[_c('span',{staticClass:"user-nft__collection__text",domProps:{"textContent":_vm._s(_vm.item.collection.name)}}),_vm._v(" "),(_vm.item.collection.isVerified)?_c('icon-verified',{staticClass:"user-nft__collection__icon",attrs:{"viewBox":"0 0 16 16"}}):_vm._e()],1):_c('span',{staticClass:"user-nft__collection",domProps:{"textContent":_vm._s(_vm.$t('address.nfts.no_collection'))}}),_vm._v(" "),_c('h3',{staticClass:"user-nft__name",domProps:{"textContent":_vm._s(_vm.item.name)}})],1)
}]

export { render, staticRenderFns }