<template>
    <nav class="interval-selector interval-selector-jetton">
        <div v-for="([name], index) in intervals"
            v-bind:key="index"
            class="interval-selector__item"
            v-bind:class="{ 'interval-selector__item--active': name == currentInterval.name }"
            v-on:click="changeIntervalIndex(index)">
            {{ name }}
        </div>
    </nav>
</template>

<script>
export const INTERVAL_ONE_DAY = 1;
export const INTERVAL_TWO_WEEKS = 14;
export const INTERVAL_MONTH = 30;
export const INTERVAL_YEAR = 365;
export const INTERVAL_ALL = 1000;

export default {
    props: {
        value: {
            type: Number,
            validator(value) {
                return [INTERVAL_ONE_DAY, INTERVAL_TWO_WEEKS, INTERVAL_MONTH, INTERVAL_YEAR, INTERVAL_ALL].includes(value);
            },
        },
    },

    data() {
        return {
            currentIntervalIndex: 0,
        };
    },

    computed: {
        intervals() {
            return [
                [this.$t('common.day'), INTERVAL_ONE_DAY],
                [this.$t('common.week'), INTERVAL_TWO_WEEKS],
                [this.$t('common.month'), INTERVAL_MONTH],
                [this.$t('common.year'), INTERVAL_YEAR],
                [this.$t('common.all'), INTERVAL_ALL],
            ];
        },

        currentInterval() {
            const [name, length] = this.intervals[this.currentIntervalIndex];
            return { name, length };
        },
    },

    mounted() {
        const idx = this.value !== undefined
            ? this.intervals.findIndex(([_, length]) => length === this.value)
            : 1;

        this.changeIntervalIndex(idx);
    },

    methods: {
        changeIntervalIndex(index) {
            this.currentIntervalIndex = index;
            this.$emit('input', this.currentInterval.length);
        },
    },
};
</script>
