<template>
    <section class="apps-list">
        <app-list v-bind:apps="apps"/>

        <app-list-skeleton
            v-show="loadingMoreApps"
            v-bind:count="appsPerPage"/>

        <template v-if="apps.length > 0">
            <ui-mugen-scroll
                v-bind:handler="loadMoreApps"
                v-bind:shouldHandle="!loadingMoreApps && !reachedEnd"
                v-bind:showButton="!reachedEnd"
            />
        </template>

        <search-not-found class="app-list__not-found"
            v-if="!loadingMoreApps && apps.length === 0"/>
    </section>
</template>

<script>
import { searchApps } from '~/components/apps/index.js';
import SearchNotFound from '~/components/apps/Navigation/SearchNotFound.vue';
import AppListSkeleton from '~/components/apps/List/ListSkeleton.vue';
import AppList from '~/components/apps/List/AppList.vue';

export default {
    name: 'ListWrapper',

    inject: [
        'getCurrentCategory',
        'getCurrentFilter',
        'setCurrentFilter',
        'getCategories',
    ],

    props: {
        searchValue: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            appsPerPage: 30,
            loadingMoreApps: true,
            page: 1,
            apps: [],
            found: 0,
        };
    },

    computed: {
        categories() {
            return this.getCategories();
        },

        currentFilter() {
            return this.getCurrentFilter();
        },

        currentCategory() {
            return this.getCurrentCategory();
        },

        reachedEnd() {
            return this.found === this.apps.length;
        },
    },

    watch: {
        searchValue: 'resetApps',
        currentCategory: 'resetApps',
        currentFilter: 'updateFilter',
    },

    async beforeMount() {
        await this.resetApps();
    },

    methods: {
        async updateFilter(filter) {
            this.setCurrentFilter(filter);
            await this.resetApps();
        },

        getParams() {
            const params = {
                per_page: this.appsPerPage,
                query_by: 'name,tags,category_slug',
                page: this.page,
                filter: 'weight:>0',
                q: '*',
            };

            const sortBy = this.currentFilter;

            if (sortBy === 'popular') {
                params.sort_by = 'weight:DESC';
            } else if (sortBy === 'newest') {
                params.sort_by = 'created_at:DESC';
            }

            if (this.searchValue) {
                params.q = this.searchValue;
            } else if (this.currentCategory) {
                params.filter_by = `category_slug:${this.currentCategory} && weight:>0`;
            }

            return params;
        },

        async getAppsWithCategoryAndFilter() {
            const params = this.getParams();
            return this.getApps(params);
        },

        async resetApps() {
            this.page = 1;
            this.apps = [];
            this.loadingMoreApps = true;

            this.apps = await this.getAppsWithCategoryAndFilter();
            this.page += 1;
            this.loadingMoreApps = false;
        },

        async loadMoreApps() {
            this.loadingMoreApps = true;

            const newApps = await this.getAppsWithCategoryAndFilter();
            this.apps = [...this.apps, ...newApps];
            this.page += 1;
            this.loadingMoreApps = false;
        },

        async getApps(params) {
            const { found, hits } = await searchApps(params);
            this.found = found;
            return hits;
        },
    },

    components: {
        SearchNotFound,
        AppListSkeleton,
        AppList,
    },
};
</script>
