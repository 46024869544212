<template>
    <ui-link
        class="user-nft"
        v-if="item.image.type !== 'NftContentNotLoaded'"
        v-bind:to="{
            name: item.kind === 'DnsItem' ? 'address' : 'nft',
            params: {
                address: item.address,
                skeletonHint: 'item',
            },
        }">
        <div class="user-nft__image">
            <template v-if="item.image">
                <img class="user-nft__image__img" decoding="async"
                    v-if="item.image.type === 'NftContentImage' && item.image.thumb && item.image.thumb.sized && !imageError"
                    v-bind:src="item.image.thumb.sized"
                    v-bind:loading="idx > 4 ? 'lazy' : 'eager'"
                    v-bind:alt="item.name"
                    v-on:error="imageError = true">

                <div class="user-nft__image__broken" v-else-if="imageError">
                    <icon-broken />
                </div>

                <img class="user-nft__image__img" decoding="async"
                    v-else-if="item.image.type === 'NftContentLottie' && item.image.preview && item.image.preview.sized"
                    v-bind:src="item.image.preview.sized"
                    v-bind:loading="idx > 4 ? 'lazy' : 'eager'"
                    v-bind:alt="item.name">

                <img class="user-nft__image__img" decoding="async"
                    v-else-if="item.image.type === 'NftContentVideo' && item.image.cover"
                    v-bind:src="item.image.cover"
                    v-bind:loading="idx > 4 ? 'lazy' : 'eager'"
                    v-bind:alt="item.name">
            </template>
        </div>

        <footer v-once class="user-nft__footer">
            <ui-link class="user-nft__collection"
                v-if="item.collection"
                v-bind:to="{
                    name: 'nft',
                    params: {
                        address: item.collection.address,
                        skeletonHint: 'collection',
                    },
                }">
                <span class="user-nft__collection__text" v-text="item.collection.name"/>
                <icon-verified class="user-nft__collection__icon" v-if="item.collection.isVerified" viewBox="0 0 16 16"/>
            </ui-link>

            <span v-else class="user-nft__collection" v-text="$t('address.nfts.no_collection')"/>

            <h3 class="user-nft__name" v-text="item.name"/>
        </footer>
    </ui-link>
</template>

<script>
import IconBroken from '@img/icons/tonscan/image-broken.svg?vue';
import IconVerified from '@primer/octicons/build/svg/verified-16.svg?vue';

export default {
    props: {
        idx: Number,
        item: Object,
    },

    data() {
        return {
            imageError: false,
        };
    },

    components: {
        IconBroken,
        IconVerified,
    },
};
</script>
