import { NEWS_API_ENDPOINT } from '~/config.js';
import axios from 'axios';

const http = axios.create({
    baseURL: NEWS_API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getNews = function (channels, limit) {
    return http.get(`/channels/lenta?channel_id=${channels}&limit=${limit}`).then(({ data }) => data);
};
