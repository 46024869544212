var render = function render(){var _vm=this,_c=_vm._self._c;return _vm._m(5)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"tx-table__cell"},[_c('span',{staticClass:"tx-table-cell-skeleton"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"tx-table__cell"},[_c('span',{staticClass:"tx-table-cell-skeleton",staticStyle:{"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"tx-table__cell"},[_c('span',{staticClass:"tx-table__badge tx-table__badge--skeleton"},[_vm._v(" ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"tx-table__cell"},[_c('span',{staticClass:"tx-table-cell-skeleton",staticStyle:{"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right"},[_c('span',{staticClass:"tx-table-cell-skeleton",staticStyle:{"width":"50%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('div',{staticClass:"tx-table-cell-icon"},[_c('svg')])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('td',[_c('div',{staticClass:"tx-table-cell"},[_c('span',{staticClass:"tx-table-cell-skeleton"},[_c('svg',{staticClass:"tx-table-expand-caret"})])])])])
}]

export { render, staticRenderFns }