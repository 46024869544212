<template>
    <a class="card-title-help dyor-logo" target="_blank" href="https://dyor.io/">
        <icon-title-help class="card-title-help__icon"/>
        <span>{{ $t('jetton_filters.dyor.data_from' )}} DYOR.io</span>
    </a>
</template>

<script>
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';

export default {
    components: {
        IconTitleHelp,
    },
};
</script>

<style scoped lang="scss">
.dyor-logo {
    width: auto;
    white-space: pre;
    padding-right: 14px;
}
</style>
