<template>
    <section>
        <div class="card">
            <header class="card-title" v-text="$t('jetton.wallet.title')"/>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('address.info.address')"/>
                <div class="card-row__value">
                    <span v-if="addressMeta.isScam" class="card-main-address-badge card-main-address-badge--scam">SCAM</span>
                    <ui-copy-button v-bind:successMessage="$t('address.info.copy_success')" v-bind:copy="address">
                        {{address}}
                    </ui-copy-button>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('jetton.wallet.owner')"/>
                <div class="card-row__value">
                    <ui-address v-if="owner_address" v-bind:address="owner_address"/>
                    <span v-else class="skeleton">SomeAddressString</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('address.info.balance')"/>
                <div class="card-row__value" v-if="wallet.balance == '0' || wallet.balance">
                    {{$ton(wallet.balance)}}
                    <span v-text="addressMeta.tonIcon || 'TON'" title="TON"/>

                    <template v-if="wallet.balance != '0'">
                        <span v-if="$store.state.exchangeRate" style="color: #717579">
                            ≈ <ui-fiat v-bind:tonValue="wallet.balance"/>
                        </span>
                    </template>
                </div>
                <div v-else class="card-row__value">
                    <span class="skeleton">00000 TON ≈ 00000 USD</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('address.info.jettons')"/>
                <div class="card-row__value" v-if="balance === 0 || balance">
                    {{$ton(balance, jetton.decimals)}}
                    <ui-link v-bind:to="jettonPageLink">
                        {{displayedTicker}}
                    </ui-link>
                </div>

                <div v-else class="card-row__value">
                    <span class="skeleton">100 Tokens</span>
                </div>
            </div>
        </div>

        <jetton-tabs v-bind:address="address">
            <template v-slot:customFilter>
                <custom-filter v-if="$store.state.filterShow" />
            </template>
        </jetton-tabs>
    </section>
</template>

<script>
import JettonTabs from './JettonTabs.vue';
import CustomFilter from '../address/Filter/Filter.vue';

export default {
    props: {
        address: String,
        owner_address: String,
        jetton_address: String,
        balance: String,
        wallet: Object,
        jetton: {
            type: Object,
            default: () => ({ image: {} }),
        },
    },

    computed: {
        addressMeta() {
            return this.$store.getters.getAddressMeta(this.address);
        },

        displayedTicker() {
            if (this.jetton?.symbol && this.jetton.symbol.match(/^[a-z]{2,16}$/i)) {
                return this.jetton.symbol;
            }

            return this.$t('jetton.tokens');
        },

        jettonPageLink() {
            return {
                name: 'jetton',
                params: {
                    address: this.jetton_address,
                    skeletonHint: 'jetton',
                },
            };
        },
    },

    components: {
        JettonTabs, CustomFilter,
    },
};
</script>
