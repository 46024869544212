<template>
    <div>
        <span class="jetton-meta-price__change" v-bind:class="getClassName">
            {{ value }}%
        </span>
    </div>
</template>

<script>
export default {
    props: {
        daily_change: Number,
    },

    computed: {
        getClassName() {
            return Number(this.daily_change) >= 0 ? 'jetton-meta-price__change--green' : 'jetton-meta-price__change--red';
        },
        value() {
            return Math.abs(this.daily_change).toFixed(2);
        },
    },
};
</script>

<style lang="scss">
.jetton-meta-price {
    &__change {
        padding: 3px 6px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;

        &--green {
            color: var(--badge-green-color);
            background-color: var(--badge-green-background-color);

            &::before {
                margin-right: -2px;
                content: '+';
            }
        }

        &--red {
            color: var(--badge-red-color);
            background-color: var(--badge-red-background-color);

            &::before {
                margin-right: -2px;
                content: '—';
            }
        }

        &--skeleton {
            color: transparent;
            background-color: var(--body-light-muted-color);
        }
    }
}
</style>
