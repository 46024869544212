<template>
    <section>
        <jetton-wallet v-if="type === 'jetton_wallet' || skeletonHint === 'jetton_wallet'"
            v-bind:address="address"
            v-bind="props"
            v-bind:wallet="wallet"
        />
    </section>
</template>

<script>
import { getAddressInfo, getJettonInfo } from '~/api';
import { showToast } from '~/toast.js';
import JettonWallet from './JettonWallet.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
        skeletonHint: {
            type: String,
            default: 'jetton_wallet',
            required: false,
        },
    },

    data() {
        return {
            type: undefined,
            props: undefined,
            activeTab: 'transactions',
            wallet: {},
        };
    },

    watch: {
        $route: 'loadData',
    },

    created() {
        this.loadData();
    },

    methods: {
        reset() {
            this.props = undefined;
            this.type = undefined;
        },

        async loadData() {
            this.reset();
            const result = await getJettonInfo(this.address);
            this.wallet = await getAddressInfo(this.address);

            switch (result.type) {
                case 'jetton_wallet': {
                    let jettonMeta = result.jetton?.metadata;

                    // So that the component use the default prop:
                    if (!jettonMeta) {
                        jettonMeta = undefined;
                    }

                    this.props = Object.freeze({ ...result.jetton_wallet, jetton: jettonMeta });
                    this.type = 'jetton_wallet';
                    break;
                }

                case 'jetton': {
                    this.$router.replace(this.$localizeRoute({
                        name: 'jetton',
                        params: { address: this.address },
                    }));
                    break;
                }

                default: {
                    showToast(this.$t('jetton.error.redirect_invalid'));

                    this.$router.replace(this.$localizeRoute({
                        name: 'address',
                        params: { address: this.address },
                    }));
                }
            }
        },
    },

    components: {
        JettonWallet,
    },
};
</script>
