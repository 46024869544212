<template>
    <section>
        <div v-if="emptyHistory" class="tx-history-empty-panel" v-text="$t('address.tx_table.empty')" />

        <div v-else class="tx-history-wrap desktop-table tx-history-nft-table">
            <table class="tx-table">
                <thead v-if="!isMobile">
                    <tr>
                        <th v-pre width="40"></th>
                        <th width="200">
                            <div
                                class="tx-table__cell"
                            >
                                {{ $t("nft.item.age_tx") }}
                            </div>
                        </th>
                        <th>
                            <div
                                class="tx-table__cell tx-table__cell--align-left"
                            >
                                {{ $t("nft.item.value_tx") }}
                            </div>
                        </th>
                        <th>
                            <div
                                class="tx-table__cell tx-table__cell--align-right"
                            >
                                {{ $t("jetton_history.maker") }}
                            </div>
                        </th>
                    </tr>
                </thead>

                <template v-if="history.length === 0">
                    <component
                        v-bind:is="isMobile ? 'jetton-tab-history-skeleton-mobile' : 'jetton-tab-history-skeleton'"
                        v-for="i in 8"
                        v-bind:key="`tx_skeleton_${i}`"
                    />
                </template>

                <template v-else>
                    <component
                        v-bind:is="isMobile ? 'jetton-tab-history-row-mobile' : 'jetton-tab-history-row'"
                        v-for="tx in history"
                        v-bind:hash="tx.hash"
                        v-bind:timestamp="tx.timestamp"
                        v-bind:from="tx.from"
                        v-bind:to="tx.to"
                        v-bind:initiator="tx.initiator"
                        v-bind:type="tx.type"
                        v-bind:jettonAddress="address"
                        v-bind:key="tx.hash"
                    />
                </template>
            </table>
        </div>
        <ui-mugen-scroll
            v-bind:handler="loadData"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:showButton="showPreloader"
            v-bind:isLoading="isLoading"
        />
    </section>
</template>

<script>
import JettonTabHistorySkeleton from '~/components/jetton/JettonTabHistorySkeleton.vue';
import JettonTabHistorySkeletonMobile from '~/components/jetton/JettonTabHistorySkeletonMobile.vue';
import JettonTabHistoryRow from '~/components/jetton/JettonTabHistoryRow.vue';
import JettonTabHistoryRowMobile from '~/components/jetton/JettonTabHistoryRowMobile.vue';
import { getJettonTradeHistoryDyor } from '~/api/dyor';

export default {
    props: ['address', 'dyorId'],
    data() {
        return {
            history: [],
            isLoading: true,
            hasMore: true,
            limit: 20,
            offset: 0,
            emptyHistory: false,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.address
                && this.hasMore
                && this.history.length > 0;
        },

        showPreloader() {
            return this.address
                && this.history.length > 0
                && this.hasMore;
        },
    },

    watch: {
        dyorId(newValue) {
            if (newValue === null) {
                this.emptyHistory = true;
                this.hasMore = false;
            } else {
                this.loadData();
            }
        },
    },

    methods: {
        async loadData() {
            this.isLoading = true;

            const txns = await getJettonTradeHistoryDyor(this.dyorId, this.limit, this.offset);
            this.history = this.history.concat(txns);
            if (this.history.length === 0) {
                this.emptyHistory = true;
                this.hasMore = false;
            }
            if (txns.length < this.limit) this.hasMore = false;
            this.offset += this.limit;
            this.isLoading = false;
        },
    },

    components: {
        JettonTabHistorySkeleton,
        JettonTabHistorySkeletonMobile,
        JettonTabHistoryRow,
        JettonTabHistoryRowMobile,
    },
};
</script>

<style scoped lang="scss"></style>
