<template>
    <div class="widget-wallet-item">
        <ui-address v-bind:address="address"/>
        <div class="widget-wallet-data">
            <div class="widget-wallet-balance">
                {{ formattedBalance }}
            </div>
            <div class="widget-wallet-icon">
                <IconTon v-once />
            </div>
        </div>
    </div>
</template>

<script>
import { MULTIPLIER } from '~/helpers';
import IconTon from '@img/icons/tonscan/ton-24.svg?inline';

export default {
    props: {
        address: String,
        balance: String,
    },

    computed: {
        formattedBalance() {
            return (Math.round(this.balance / MULTIPLIER)).toLocaleString();
        },
    },

    components: {
        IconTon,
    },
};
</script>
