<template>
    <div>
        <div class="widget-content widget-channel-container">
            <template v-if="newsData.length > 0">
                <news-feed-item
                    v-for="(news, index) in newsData"
                    v-bind:key="`news-id-${index}`"
                    v-bind:id="news.id"
                    v-bind:channel-id="news.channelId"
                    v-bind:channel-username="news.channelUsername"
                    v-bind:channel-name="news.channelName"
                    v-bind:text="news.text"
                    v-bind:date="news.date"
                />
            </template>
            <template v-else>
                <news-feed-skeleton
                    v-for="i in 4"
                    v-bind:key="`news-skeleton-${i}`"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getNews } from '~/api/news.js';
import NewsFeedItem from '../../NewsFeed/NewsFeedItem.vue';
import NewsFeedSkeleton from '../../NewsFeed/NewsFeedSkeleton.vue';

export default {
    data() {
        return {
            newsData: [],
            isLoading: true,
            // hasMore: true,
        };
    },

    computed: {
        ...mapState({
            newsLanguage: state => state.appLocale,
        }),

        shouldHandleScroll() {
            return this.isLoading && this.hasMore && this.newsData.length > 0;
        },

        showPreloader() {
            return this.newsData.length > 0 && this.hasMore;
        },
    },

    watch: {
        newsLanguage: {
            immediate: false,
            async handler() {
                this.hasMore = true;
                this.newsData = [];
                await this.getNewsInfo();
            },
        },
    },

    mounted() {
        this.getNewsInfo();
    },

    methods: {
        async getNewsInfo() {
            const lang = this.$i18n.locale;
            try {
                const limit = 24;
                const channels = {
                    ru: [1433990798, 2020833003, 1444152677],
                    en: [1191264954, 1744725639, 1379327992, 1497424960],
                };

                const newsByLang = lang === 'ru'
                    ? await getNews(channels.ru, limit)
                    : await getNews(channels.en, limit);

                const { channels: channelData, posts: newsData } = newsByLang;

                const parsedData = newsData.map(({ channel_id, post_id, date, text }) => {
                    const { username, title } = channelData[channel_id];
                    return {
                        channelId: channel_id,
                        channelUsername: username,
                        channelName: title,
                        id: post_id,
                        date,
                        text,
                    };
                });

                this.newsData = parsedData;
                if (parsedData.length < 8) this.hasMore = false;
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },

    components: {
        NewsFeedItem,
        NewsFeedSkeleton,
    },
};
</script>

<style lang="scss">
.widget-channel-container {
    max-height: 538px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
</style>
