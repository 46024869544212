<template>
    <tr class="jetton-link" v-on:click="$router.push({
        name: 'jetton',
        params: {
            address: address,
            skeletonHint: 'jetton'
        }
    })">
        <td class="muted">{{ index }}</td>
        <td class="jetton-image">
            <ui-round-image class="jetton-indexer-table__icon" size="tiny" v-bind:src="img" />
        </td>
        <td class="jetton-title">
            <div class="jetton-title-items">
                <div>
                    <span style="font-weight: 500">{{ name }}</span> <span v-if="!isMobile" class="muted">{{ symbol }}</span>
                </div>
                <div class="jetton-ticker">
                    <template v-if="isMobile">
                        <span class="jetton-cap muted">
                            {{ symbol }}
                        </span>
                    </template>
                </div>
            </div>
        </td>
        <td class="jetton-price">
            <p>
                <ui-format-price style="font-weight: 500" v-bind:price="price" />
            </p>
            <p>
                <template v-if="isMobile">
                    <percent-change v-bind:change="priceChange24h" v-bind:showPercent="isMobile" />
                </template>
                <!-- <template v-else>
                    <span class="muted">{{ $ton(price) }} TON</span>
                </template> -->
            </p>
        </td>
        <td class="jetton-hour" v-if="!isMobile">
            <percent-change v-bind:change="priceChange24h" />
        </td>
        <td class="jetton-day" v-if="!isMobile">
            <percent-change v-bind:change="priceChange1w" />
        </td>
        <td class="jetton-week" v-if="!isMobile">
            <percent-change v-bind:change="priceChange1m" />
        </td>
        <td class="jetton-mc" v-if="!isMobile">
            <span v-if="Number(traders) !== 0">{{ $round(traders) }}</span>
            <span v-else class="muted">—</span>
        </td>
        <td class="jetton-volume" v-if="!isMobile">
            <ui-format-price
                v-if="volume"
                v-bind:price="volume"
                v-bind:format="true"
                style="font-weight: 500"
            />
            <template v-else>
                <p class="muted">—</p>
            </template>
        </td>
        <td class="jetton-last" v-if="!isMobile">
            <p class="skeleton" style="height: 38px; width: 135px; border-radius: 9px;" v-show="!imgLoaded">icon</p>
            <img class="jetton-theme-chart" v-bind:src="chartByTheme" v-show="imgLoaded" v-on:load="imgLoaded = true" />
        </td>
    </tr>
</template>

<script>
import PercentChange from './PercentChange.vue';

export default {
    props: {
        index: {
            type: Number,
        },
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        address: {
            type: String,
        },
        symbol: {
            type: String,
        },
        price: {
            type: String,
        },
        volume: {
            type: String,
        },
        chartData: {
            type: String,
        },
        priceChange24h: {
            type: String,
        },
        priceChange1w: {
            type: String,
        },
        priceChange1m: {
            type: String,
        },
        traders: {
            type: Number,
        },
        chartLight: {
            type: String,
        },
        chartDark: {
            type: String,
        },
    },

    data() {
        return {
            labels: undefined,
            datasets: undefined,
            chartLoaded: false,
            imgLoaded: false,
        };
    },

    computed: {
        chartByTheme() {
            const theme = this.$store.state.appTheme;

            return theme === 'light' ? this.chartLight : this.chartDark;
        },
    },

    components: {
        PercentChange,
    },
};
</script>

<style lang="scss">
.jetton-link {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    cursor: pointer;
    height: 72px;
}

.jetton-cap {
    font-weight: 400;
}

.jetton-link:hover {
    background: var(--body-light-muted-color);
}

.jetton-name,
.jetton-ticker {
    font-weight: 500;
    word-break: normal;
    max-width: 130px;
    overflow-wrap: break-word;
    white-space: normal;
}

.jetton-title-items {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 599px) {
    .jetton-title-items {
        flex-direction: column;
        text-align: left;
        gap: 0;
        align-items: normal;
    }
    .jetton-name {
        padding-bottom: 3px;
    }
    .jetton-ticker {
        max-width: none!important;
    }
    .jetton-image {
        width: 40px;
    }
}

.jetton-title {
    padding: 0 12px 0 0!important;
}

.jetton-name {
    width: 200px;
}

.jetton-image {
    padding: 0 12px !important;
    width: 32px;
}

.jetton-price {
    text-align: right;
    white-space: nowrap;
}

.jetton-ticker {
    font-weight: 400;
    color: var(--body-muted-text-color);
}

.jetton-hour {
    font-weight: 500;
    text-align: center;
    padding-left: 30px;
}

.jetton-day {
    font-weight: 500;
    text-align: center;
}

.jetton-week {
    font-weight: 500;
    text-align: center;
}

.jetton-mc {
    font-weight: 500;
    text-align: center;
}

.jetton-volume {
    text-align: center;
}

.jetton-theme-chart {
    height: 45px;
}
</style>
