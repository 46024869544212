<template>
    <div>
        <div class="widget-content">
            <div class="widget-apps">
                <template v-if="appsData">
                    <trending-app-item
                        v-for="(app, index) in appsData"
                        v-bind:key="index"
                        v-bind:name="app.title"
                        v-bind:description_en="app.descriptions.get('en')"
                        v-bind:description_ru="app.descriptions.get('ru')"
                        v-bind:image="`https://i.tonscan.org/apps${app.icon}`"
                        v-bind:link="`https://tonscan.org/apps/${app.categorySlug}/${app.slug}/`"
                    />
                </template>
                <template v-else>
                    <trending-app-item-skeleton
                        v-for="i in 5"
                        v-bind:key="i"
                    />
                </template>
            </div>
            <div class="mobile-tab-btn__container">
                <ui-link v-bind:to="{ name: 'apps' }" class="mobile-tab-btn">
                    <div class="mobile-tab-btn__title">
                        {{ $t('indexpage.mobile.all_apps') }}
                    </div>
                    <div class="mobile-tab-btn__icon">
                        <IconArrow />
                    </div>
                </ui-link>
            </div>
        </div>
    </div>
</template>

<script>
import { searchApps } from '~/components/apps/index.js';
import IconArrow from '@img/icons/tonscan/arrow-small-right.svg?vue';
import TrendingAppItem from '../../TrendingApps/TrendingAppItem.vue';
import UiLink from '../../../UiLink.vue';
import TrendingAppItemSkeleton from '../AppsSkeleton.vue';

export default {
    data() {
        return {
            appsData: null,
        };
    },

    async mounted() {
        await this.loadTopApps();
    },

    methods: {
        async loadTopApps() {
            try {
                const params = {
                    per_page: 5,
                    query_by: 'name,tags,category_slug',
                    page: 1,
                    filter: 'weight:>0',
                    q: '*',
                    sort_by: 'weight:DESC',
                };

                const { hits } = await searchApps(params);
                this.appsData = hits;
            } catch (error) {
                console.error('Error loading top apps:', error);
            }
        },
    },

    components: {
        TrendingAppItem,
        UiLink,
        TrendingAppItemSkeleton,
        IconArrow,
    },
};
</script>

<style lang="scss">

@media screen and (max-width: 600px) {
    .widget-apps {
        padding: 5px 0 10px 0;
    }

    .widget-apps-item {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        margin: 0 0 8px 0;
        border-radius: 10px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }

    .widget-apps a:hover {
        background: var(--body-light-muted-color);
    }

    .widget-apps-item:last-child {
        margin-bottom: 0;
    }

    .widget-apps-item:hover {
        text-decoration: none;
    }

    .widget-apps-img {
        width: 60px;
        flex: 0 0 60px;
        border-radius: 14px;
        margin-right: 18px;
        overflow: hidden;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }

    .widget-apps-name {
        color: var(--body-text-color);
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 500;
    }

    .widget-apps-description {
        color: var(--body-muted-text-color);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }

    .widget-apps-item img {
        width: 100%;
        vertical-align: top;
    }
}
</style>
