<template>
    <div>
        <div class="widget-content widget-channel-container">
            <top-jetton-skeleton v-if="!jettonsData" v-bind:rows="limit" />
            <div class="widget-jettons" v-else>
                <top-jetton-item
                    v-for="(jetton, index) in jettonsData"
                    v-bind:key="index"
                    v-bind:img="jetton.img"
                    v-bind:name="jetton.name"
                    v-bind:price="jetton.price"
                    v-bind:symbol="jetton.symbol"
                    v-bind:jetton="jetton.jetton"
                    v-bind:change="jetton.priceChange24h"
                />
            </div>
        </div>
        <div class="mobile-tab-btn__container">
            <ui-link v-bind:to="{ name: 'market' }" class="mobile-tab-btn">
                {{ $t('indexpage.mobile.show_all') }}
            </ui-link>
        </div>
        <div class="dyor-logo-widget muted">
            <span v-html="$t('jetton_filters.dyor.data_source' )" />
        </div>
    </div>
</template>

<script>
import { getTopMCJettonsList } from '~/api/dyor.js';
import TopJettonItem from '../../TopJettons/TopJettonItem.vue';
import TopJettonSkeleton from '../../TopJettons/TopJettonItemSkeleton.vue';

export default {
    data() {
        return {
            jettonsData: undefined,
            limit: 6,
        };
    },

    mounted() {
        this.getTrendingInfo();
    },

    methods: {
        async getTrendingInfo() {
            try {
                this.jettonsData = await getTopMCJettonsList(this.limit);
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        TopJettonItem,
        TopJettonSkeleton,
    },
};
</script>

<style lang="scss">
.widget-jettons a:hover {
    background: var(--body-light-muted-color);
}

@media screen and (max-width: 599px) {
    .widget-jettons > .widget-jettons-item {
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
