import axios from 'axios';
import { DYOR_ENDPOINT } from '~/config.js';
import { canonizeAddress } from '~/tonweb.js';

const http = axios.create({
    baseURL: DYOR_ENDPOINT,
});

// eslint-disable-next-line default-param-last
export const getTrendingJettonsList = async function getTrendingDyorJettons(limit = 3, offset = 0, category) {
    const params = {
        limit,
        offset,
        category,
    };

    const { data: list } = await http.get('/jettons/all', { params });

    return list;
};

export const getTopMCJettonsList = async function getDyorJettonsTopByMarketCap(limit = 3) {
    const params = {
        limit,
    };

    const { data: list } = await http.get('/jettons/top', { params });

    return list;
};

export const getJettonInfoDyor = async function getJettonInfoByAddress(address) {
    const { data } = await http.get(`/api/proxy/jettons/addr/${address}/details`);

    return data;
};

export const getJettonTradeHistoryDyor = async function getJettonIdByAddress(jtnId, limit = 20, offset = 0) {
    if (!jtnId) {
        return [];
    }

    const params = {
        swapTypes: ['buy', 'sell'],
        jtnId,
        limit,
        offset,
    };

    const { data: pairs } = await http.get('/api/proxy/jettons/txns', { params });

    const jettons = pairs.jettons;
    const txns = pairs.txns;
    const result = [];

    txns.forEach((tx) => {
        const fromId = tx.type === 'buy' ? jettons[tx.pair2Id] : jettons[tx.pair1Id];
        const toId = tx.type === 'buy' ? jettons[tx.pair1Id] : jettons[tx.pair2Id];

        const fromAmount = tx.type === 'buy' ? tx.pair2Amount : tx.pair1Amount;
        const toAmount = tx.type === 'buy' ? tx.pair1Amount : tx.pair2Amount;

        const temp = {
            hash: tx.hash,
            timestamp: new Date(tx.time).getTime(),
            from: {
                address: fromId?.address,
                symbol: fromId?.symbol,
                image: fromId?.image,
                amount: fromAmount,
            },
            to: {
                address: toId?.address,
                symbol: toId?.symbol,
                image: toId?.image,
                amount: toAmount,
            },
            initiator: canonizeAddress(tx.who, { type: 'wallet' }),
            type: tx.type,
        };

        result.push(temp);
    });

    return result;
};
