<template>
    <div class="card ads-page-block">
        <div class="ads-page-block__text">
            <div class="ads-page-block__header">
                {{ $t(`ads_page.block_${bannerId}.header`) }}
            </div>
            {{ $t(`ads_page.block_${bannerId}.text`) }}
        </div>
        <div v-if="!showBanner" class="ads-page-block__banner--skeleton" v-bind:id="`ads-banner-skeleton-${bannerId}`"></div>
        <transition name="fade-banner">
            <img v-show="showBanner"
                v-bind:src="bannerImg"
                v-on:load="showBanner = true"
                class="ads-page-block__banner" />
        </transition>
    </div>
</template>

<script>
export default {
    props: ['bannerId', 'bannerImg'],
    data() {
        return {
            showBanner: false,
        };
    },
};
</script>

<style scoped lang="scss">
.ads-page-block {
    padding: 0;
    overflow: hidden;
    background-color: var(--advertise-page-block-bg);

    &__text {
        padding: 20px 30px 30px;
        text-align: center;
    }

    &__header {
        font-weight: 500;
        font-size: 20px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    &__banner {
        width: 100%;
        height: auto;
        max-height: 277px;
        object-fit: cover;
        object-position: bottom;

        &--skeleton {
            width: 100%;
            position: relative;
            background: var(--body-light-muted-color);
            color: transparent;
            overflow: hidden;

            &::after {
                content: "";
                border-radius: inherit;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 8%, rgba(255, 255, 255, 0.08) 18%, rgba(255, 255, 255, 0.04) 33%);
                background-size: 200% 100%;
                animation: 1.5s shine linear infinite;
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

#ads-banner-skeleton-1,
#ads-banner-skeleton-3,
#ads-banner-skeleton-5 {
    height: 277px!important;
}

#ads-banner-skeleton-2,
#ads-banner-skeleton-4,
#ads-banner-skeleton-6{
    height: 125px!important;
}

.fade-banner-enter, .fade-banner-leave-to {
    opacity: 0;
}
.fade-banner-enter-active, .fade-banner-leave-active {
    transition: opacity 2.8s;
}
</style>
