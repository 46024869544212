<template>
    <search-result class="search-result-app"
        show-avatar
        v-bind:avatarUrl="image"
        v-bind:name="name">

        <template v-slot:nameSuffix>
            <span class="search-result-app__tag">App</span>
        </template>

        <template v-slot:contents>
            <p class="search-result-app__description" v-text="description"/>
        </template>
    </search-result>
</template>

<script>
import SearchResult from './AppSearchResult.vue';

export default {
    props: {
        image: String,
        name: String,
        description: String,
    },

    components: {
        SearchResult,
    },
};
</script>

<style lang="scss">
.search-result-app {
    &__tag {
        font-size: 10px;
        padding: 1px 7px;
        background: var(--card-pill-background);
        border-radius: 10px;
        margin: -2px 8px;
        font-weight: 400;
        color: var(--app-search-badge-text-color);
    }
    &__description {
        margin: 0;
        font-size: 13px;
        opacity: .7;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>
