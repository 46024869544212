<template>
    <ui-link v-bind:to="{ name: 'jetton', params: { address: jetton }}" class="widget-jettons-item">
        <div>
            <div class="widget-jettons-img">
                <ui-round-image class="widget-jettons-icon" size="tiny" v-bind:src="img" />
            </div>
        </div>
        <div class="widget-jettons-content">
            <div class="widget-jettons-name">
                {{ name }}
            </div>
            <div class="widget-jettons-symbol muted">
                {{ symbol }}
            </div>
        </div>
        <div class="widget-jettons-data">
            <div class="widget-jettons-price">
                <ui-fiat v-bind:tonValue="price * 10**9" />
            </div>
            <top-jetton-percent-change v-bind:change="change" />
        </div>
    </ui-link>
</template>

<script>
import TopJettonPercentChange from './TopJettonPercentChange.vue';

export default {
    props: {
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        price: {
            type: String,
        },
        symbol: {
            type: String,
        },
        jetton: {
            type: String,
        },
        change: {
            type: String,
        },
    },
    components: {
        TopJettonPercentChange,
    },
};
</script>

<style lang="scss">
.widget-jettons-icon {
    width: 32px;
    height: 32px;
}
</style>
