<template>
    <div class="card">
        <div class="modal-banner">
            <aside class="modal-banner-icon">
                <icon-subscription class="modal-banner-icon__svg"/>
            </aside>
            <div class="modal-banner__text" v-html="$t('subscription.contract_description_html')"/>
        </div>

        <div class="card-row">
            <div class="card-row__name" v-text="$t('subscription.subscriber')"/>
            <div class="card-row__value">
                <ui-address v-if="subscriber" v-bind:address="subscriber"/>
            </div>
        </div>
        <div class="card-row">
            <div class="card-row__name" v-text="$t('subscription.beneficiary')"/>
            <div class="card-row__value">
                <ui-address v-if="beneficiary" v-bind:address="beneficiary"/>
            </div>
        </div>
        <div class="card-row">
            <div class="card-row__name" v-text="$t('subscription.price')"/>
            <div class="card-row__value">
                {{$ton(amount)}} TON
                <template v-if="paymentPeriod">
                    {{$t(`subscription.period.${paymentPeriod}`)}}
                </template>
            </div>
        </div>
        <div class="card-row" v-if="startTime > 0">
            <div class="card-row__name" v-text="$t('subscription.first_payment_date')"/>
            <div class="card-row__value">
                <ui-timeago v-bind:timestamp="startTime * 1000"/>
                <span class="muted">&ndash;</span>
                <ui-datetime class="muted" v-bind:timestamp="startTime"/>
            </div>
        </div>
        <div v-if="lastPaymentTime > 0" class="card-row">
            <div class="card-row__name" v-text="$t('subscription.last_payment_date')"/>
            <div class="card-row__value">
                <ui-timeago v-bind:timestamp="lastPaymentTime * 1000"/>
                <span class="muted">&ndash;</span>
                <ui-datetime class="muted" v-bind:timestamp="lastPaymentTime"/>
            </div>
        </div>
        <div class="card-row">
            <div class="card-row__name" v-text="$t('subscription.subscription_id')"/>
            <div class="card-row__value">
                {{subscriptionId}}
            </div>
        </div>
    </div>
</template>

<script>
import IconSubscription from '@img/icons/material-duotone/currency-exchange.svg?inline';

/**
 * @param  {Number} timeRange
 * @return {Number|null}
 */
const detectInterval = function getHumanReadableRangeFromSeconds(timeRange) {
    // from 28 to 31 days
    if (timeRange >= 2332800 && timeRange <= 2764800) {
        return 'monthly';
    }

    return null;
};

export default {
    props: {
        wallet: String,
        beneficiary: String,
        amount: [Number, String],
        period: Number,
        startTime: Number,
        lastPaymentTime: Number,
        subscriptionId: [Number, String],
    },

    data() {
        return {
            subscriber: undefined,
            paymentPeriod: undefined,
        };
    },

    mounted() {
        this.subscriber = this.wallet;
        this.paymentPeriod = detectInterval(this.period);
    },

    components: {
        IconSubscription,
    },
};
</script>
