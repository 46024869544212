<template>
    <section>
        <div class="card-chain-header">
            <div class="card-chain-header__title">
                {{ title === 'masterchain' ? $t('blocks.last_masterchain_blocks_title') : $t('blocks.last_workchain_blocks_title') }}
            </div>
            <div class="card-chain-header__link">
                <ui-link v-if="blocks.length > 0" v-bind:to="{
                    name: 'blocks',
                    query: { wc: blocks[0].workchain },
                }">
                    {{$t('common.show_more')}}
                </ui-link>
            </div>
        </div>
        <div class="tx-history-wrap desktop-table">
            <table class="ui-table last-blocks-mini-table">
                <thead>
                    <tr>
                        <th v-text="$t('blocks.block')"/>
                        <th v-text="$t('blocks.transactions')"/>
                        <th v-text="$t('common.time')"/>
                    </tr>
                </thead>

                <tbody v-if="blocks.length === 0">
                    <tr v-for="i in 15" v-bind:key="`blocks_mini_table_skeleton_${i}`">
                        <td><span class="skeleton">123456789</span></td>
                        <td><span class="skeleton">3</span></td>
                        <td><span class="skeleton">21:17:22</span></td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr class="new-row-animation"
                        v-for="block in blocks"
                        v-bind:key="`${block.workchain}:${block.seqno}`">
                        <td>
                            <ui-link v-bind:to="{ name: 'block', params: { ...block } }">
                                {{block.seqno}}
                            </ui-link>
                        </td>
                        <td v-text="block.tx_count"/>
                        <td>
                            <ui-datetime hide-date v-bind:timestamp="block.gen_utime"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="card-chain-footer">
            <ui-link
                v-if="blocks.length > 0"
                v-bind:to="{
                    name: 'blocks',
                    query: { wc: blocks[0].workchain },
                }"
                class="card-chain-show-more"
            >
                <div class="card-chain-show-more__title">
                    {{$t('common.show_more')}}
                </div>
                <div class="card-chain-show-more__icon">
                    <IconArrow />
                </div>
            </ui-link>
        </div>
    </section>
</template>

<script>
import IconArrow from '@img/icons/tonscan/arrow-small-right.svg?vue';

export default {
    props: {
        blocks: {
            type: Array,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
    },

    components: {
        IconArrow,
    },
};
</script>

<style lang="scss">
.last-blocks-mini-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }
}

.card-chain-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &__title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-color);
    }

    &__link {
        text-transform: uppercase;
        font-weight: 500;
    }

    &__link a:hover {
        text-decoration: none;
        color: var(--widget-view-all-btn-hover);
    }
}

.card-chain-footer {
    display: none;
    padding: 8px 10px 18px 10px;
}

.card-chain-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: var(--body-text-color);
    border: 1px solid var(--card-border-color);
    background-color: transparent;
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;

    &__icon {
        width: 9px;
    }
}

@media screen and (max-width: 480px) {
    .card-chain-header {
        &__link {
            display: none;
        }
    }

    .card-chain-footer {
        display: block;
    }
}
</style>
