<template>
    <div>
        <div class="card-market-jetton" v-for="r in rows" v-bind:key="r">
            <div class="card-market-jetton-item">
                <div class="card-market-jetton-image" style="width: 0; padding: 0">
                </div>
                <div class="card-market-jetton-name">
                    <div class="skeleton">NNNNNNNNNN</div>
                </div>
            </div>
            <div class="card-market-jetton-item">
                <div class="skeleton" style="margin-right: 20px">NNNNN</div>
                <div class="skeleton">NNNN</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rows: {
            type: Number,
            default: 3,
        },
    },
};
</script>

<style lang="scss">
</style>
