<template>
    <section>
        <div class="verifier-chapter-container">
            <button class="verifier-chapter-btn"
                v-for="chapter in chapters"
                v-bind:class="{ 'verifier-chapter-btn__active': chapter === currentChapter }"
                v-on:click="changeChapter(chapter)"
                v-bind:key="chapter"
            >
                {{ $t(`address.contract.chapters.${chapter}`) }}
            </button>
        </div>

        <section v-if="currentChapter === 'source'">
            <verifier-admonition
                v-bind:contractAddress="address"
                v-bind:isVerified="isVerified"
                v-bind:isActive="isActive"
                v-bind:compilerName="compilerName"
                v-bind:compilerVersion="compilerVersion"
                v-bind:compilerCmd="compilerCmd"/>

            <section class="verifier-sources" v-show="isLoading || isActive">
                <aside class="verifier-sources__files">
                    <div class="verifier-sources__files__elevator">
                        <verifier-file-list v-if="tree === undefined || tree.length > 0"
                            v-bind:files="tree"
                            v-on:selectFile="selectFile"/>

                        <verifier-file-list
                            v-bind:files="rawFiles"
                            v-on:selectFile="selectRaw"/>
                    </div>
                </aside>

                <div class="verifier-sources__code">
                    <verifier-bytecode-viewer
                        v-if="currentTab.filename === 'raw_data' || currentTab.filename === 'raw_source'"
                        v-bind:source-base64="currentTab.content.base64"
                        v-bind:source-decompiled="currentTab.content.decompiled"
                        v-bind:source-cells="currentTab.content.cells"
                        v-bind:source-hex="currentTab.content.hex"
                        v-bind:sha256sum="currentTab.content.sha256sum"/>

                    <keep-alive v-else>
                        <verifier-source-code
                            v-bind:key="currentTab.content_url"
                            v-bind:fullPath="currentTab.fullPath"
                            v-bind:filename="currentTab.filename"
                            v-bind:content="currentTab.content"
                            v-bind:url="currentTab.content_url"/>
                    </keep-alive>
                </div>
            </section>
        </section>

        <address-formats
            v-if="currentChapter === 'formats'"
            v-bind:address="address"
        />

        <address-versions
            v-if="currentChapter === 'versions'"
            v-bind:address-versions-list="addressVersionsList"
        />
    </section>
</template>

<script>
// import { getAddressContractInfo } from '~/api';
import { checkAddress, getVerifiedSourceByAddress } from '~/api/extenderContracts.js';
import { base64ToHex } from '~/utils.js';
import { getAccountInfoSha256 } from '~/api/toncenterV3.js';
import AddressFormats from '~/components/address/Verifier/AddressFormats.vue';
import VerifierSourceCode from './VerifierSourceCode.vue';
import VerifierFileList from './VerifierFileList.vue';
import VerifierAdmonition from './VerifierAdmonition.vue';
import VerifierBytecodeViewer from './VerifierBytecodeViewer.vue';
import AddressVersions from './AddressVersions.vue';

/**
 * @param  {Array<Object>} sources
 * @return {Object}
 */
const generateFileTree = function createFileTreeFromFileList(sources) {
    // We need flat map of source file references for easier access:
    const flatMap = [];

    // Backup fullPath because we'll change filename prop:
    for (let i = 0; i < sources.length; i += 1) {
        sources[i].fullPath = sources[i].filename; // eslint-disable-line no-param-reassign
    }

    const makeTree = (files) => {
        const tree = [];

        for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            const frags = file.filename.split('/');

            flatMap.push(file);

            if (frags.length === 1) {
                file.isActive = false; // set reactivity on
                tree.push(file);
                continue;
            }

            const directoryName = frags[0];

            // Get all files in the directory:
            const directoryContents = files
                .filter(source => source.filename.startsWith(`${directoryName}/`))
                .map((source) => {
                    const fullPath = source.filename;
                    const filename = fullPath.replace(`${directoryName}/`, '');
                    return { ...source, filename };
                });

            // Push directories in the beginning:
            tree.unshift({
                name: directoryName,
                fullPath: frags.toSpliced(-1).join('/'),
                files: makeTree(directoryContents),
                isCollapsed: true,
            });

            // As the files are sorted and processing is sequential, we can skip all
            // with names starting with directory name:
            i += (directoryContents.length - 1);
        }

        return tree;
    };

    return { flatMap, treeMap: makeTree(sources) };
};

export default {
    rawInfoPromise: undefined,

    props: {
        isActive: Boolean,
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            sources: [],
            tree: undefined,
            isVerified: undefined,
            rawFiles: [],
            chapters: ['source', 'formats'],
            currentChapter: 'source',
            addressVersionsList: [],
            currentTab: {},
            isLoading: true,
            compilerName: undefined,
            compilerVersion: undefined,
            compilerCmd: undefined,
        };
    },

    created() {
        this.rawFiles = [{
            filename: 'raw_source',
            isActive: false,
            content: {},
            text: this.$t('address.contract.tab_raw_source'),
        }, {
            filename: 'raw_data',
            isActive: false,
            content: {},
            text: this.$t('address.contract.tab_raw_data'),
        }];
        this.loadWalletVersions();
    },

    beforeDestroy() {
        this.$options.rawInfoPromise = undefined;
    },

    watch: {
        isActive: {
            immediate: true,
            // wait until we have address activity info:
            handler() {
                return this.loadData();
            },
        },
    },

    methods: {
        async loadData() {
            if (this.isActive === undefined) {
                return;
            }

            if (this.isActive === false) {
                this.isLoading = false;
                this.isVerified = false;
                return;
            }

            // await getVerifiedSourceByAddress(this.address);
            // console.log('contract', getVeri);

            getVerifiedSourceByAddress(this.address).then((response) => {
                this.isLoading = false;
                this.isVerified = response.source_item_data?.verifier_id !== undefined;

                this.compilerName = response.source_item_data?.content?.compiler?.name || null;
                this.compilerVersion = response.source_item_data?.content?.compiler?.version;
                this.compilerCmd = response.source_item_data?.content?.compiler?.cmd;

                if (!response.source_item_data?.content?.sources) {
                    this.tree = [];
                    this.selectRaw(this.rawFiles[0]);
                    return;
                }

                const { flatMap, treeMap } = generateFileTree(response.source_item_data.content.sources);

                // root level should have reverse order (files first, directories last):
                treeMap.sort((_, b) => (b.files ? -1 : 1));
                treeMap.sort((a, _) => (a.is_entrypoint ? -1 : 1));

                this.tree = treeMap;
                this.sources = flatMap;

                this.selectFile(flatMap[0]);
            });
        },

        selectFile(selectedFile) {
            this.sources.concat(this.rawFiles).forEach((tab) => {
                tab.isActive = Object.is(tab, selectedFile); // eslint-disable-line no-param-reassign
            });

            this.currentTab = selectedFile;
        },

        selectRaw(rawSource) {
            this.selectFile(rawSource);

            this.loadRawInfo().then((contract) => {
                this.currentTab.content = rawSource.filename === 'raw_data'
                    ? contract.data
                    : contract.code;
            });
        },

        async loadRawInfo() {
            if (!this.$options.rawInfoPromise) {
                this.$options.rawInfoPromise = getAccountInfoSha256(this.address);
            }

            return this.$options.rawInfoPromise.then(async ({ code, data, sha256code, sha256data }) => ({
                code: {
                    base64: code,
                    hex: base64ToHex(code),
                    sha256sum: sha256code,
                },
                data: {
                    base64: data,
                    hex: base64ToHex(data),
                    sha256sum: sha256data,
                },
            }));
        },

        async loadWalletVersions() {
            const addressInfo = await checkAddress(this.address);
            if (addressInfo?.type === 'wallet') {
                this.addressVersionsList = addressInfo?.wallet?.alternative_addresses;
                this.chapters.push('versions');
            }
        },

        async changeChapter(chapter) {
            this.currentChapter = chapter;
        },
    },

    components: {
        VerifierSourceCode,
        VerifierBytecodeViewer,
        VerifierAdmonition,
        VerifierFileList,
        AddressFormats,
        AddressVersions,
    },
};
</script>

<style lang="scss">
.verifier-chapter-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;
    padding: 0 12px 0 0;
    margin: 10px 0;
    position: relative;
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
}

@media screen and (min-height: 600px) {
    .verifier-chapter-container {
        display: flex;
        margin: 16px 0 10px;
        overflow-x: auto;
        overflow-y: auto;
    }
}

.verifier-chapter-btn {
    padding: 8px 12px;
    text-transform: uppercase;
    color: var(--body-text-color);
    border: 1px solid var(--address-tag-gray-background);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    background: var(--button-options-background);
    transition: .1s background;
    margin-right: 12px;
    white-space: nowrap;

    &__active {
        border-color: var(--filter-item-active);
        background: var(--chapter-active-background);
    }

    &:first-child {
        margin-left: 12px;
    }

    &:hover:not(.verifier-chapter-btn__active) {
        background: var(--button-options-background-hover);
    }
}

.verifier-sources {
    display: flex;
    flex-direction: row;
    &__files {
        margin: 0 0 12px 12px;
        width: 240px;
        flex-shrink: 0;
        &__elevator {
            position: sticky;
            top: 12px;
        }
    }
    &__code {
        flex-grow: 1;
        overflow: auto;
        margin: 0 12px 12px 12px;
    }
}

.source-viewer-code__pre {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.hljs-hack-lines {
    padding: 0 10px 0 0;
    text-align: right;
    color: var(--body-muted-text-color);
}

.hljs-hack-code {
    padding: 0 10px 0 4px;
}

@media all and (max-width: 480px) {
    .verifier-sources {
        flex-direction: column;
        &__files {
            width: unset;
            margin-right: 12px;
        }
        &__code {
            margin-top: 0;
        }
    }
}
</style>
