<template>
    <div class="card-market-jetton-percent" v-bind:class="`card-jetton-indicator--${color}`">
        <span>{{ sign }}{{ value }}%</span>
    </div>
</template>

<script>
export default {
    props: {
        change: undefined,
    },
    computed: {
        color() {
            if (this.change == null) return 'gray';
            return this.change < 0 ? 'red' : 'green';
        },
        sign() {
            if (this.change == null) return '';
            return this.change < 0 ? '−' : '+';
        },
        value() {
            const t = String(this.change);
            return t.replace(/-/g, '');
        },
    },
};
</script>

  <style lang="scss">
  .card-market-jetton-percent {
      font-size: 14px;
  }

  .card-jetton-indicator {
    &--red {
      color: #f74b4c;
    }

    &--green {
      color: #3fb950;
    }

    &--gray {
      color: #888888;
    }
  }
  </style>
