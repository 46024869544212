<script>
import { getLastBlock } from '~/api';

export default {
    render: () => null,

    async created() {
        const { workchain, shard, seqno } = await getLastBlock();

        this.$router.replace(this.$localizeRoute({
            name: 'block',
            params: { workchain, shard, seqno },
        }));
    },
};
</script>
