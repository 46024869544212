<template>
    <tbody>
        <tr>
            <td>
                <!-- <ui-link class="tx-table-cell-icon" v-bind:to="transactionRoute" >
                    <icon-tx-chain fill="none"/>
                </ui-link> -->
                <ui-link class="tx-table-cell tx-table-cell-link-badge" v-bind:to="transactionRoute" >
                    <div class="tx-table__cell">
                        <div class="tx-table__badge tx-table__badge--burn" v-if="!type">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-burn />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.burn') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table__badge--sale" v-if="type === 'put-up-for-sale'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-sale />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.sale') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table-cell-icon--out" v-if="type === 'transfer'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-sent />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.sent') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table__badge--bid" v-if="type === 'put-up-for-auction'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-bid />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.bid') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table-cell-icon--in" v-if="type === 'mint'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-mint />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.mint') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table-cell-icon--out" v-if="type === 'cancel-sale'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-cancel />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.cancel') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table-cell-icon--out" v-if="type === 'cancel-auction'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-cancel-bid />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.cancel') }}
                                </div>
                            </div>
                        </div>

                        <div class="tx-table__badge tx-table__badge--sold" v-if="type === 'sold'">
                            <div class="tx-table__badge--items">
                                <div class="tx-table__badge--icon">
                                    <icon-tx-sold />
                                </div>
                                <div class="tx-table__badge--value">
                                    {{ $t('nft.item.badge.sold') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ui-link>
            </td>

            <td>
                <span class="tx-table__cell" v-if="price && type !== 'cancel-sale'">
                    <span class="tx-table-price-ton">
                        {{ $ton(price) }} TON
                    </span>
                    <template v-if="price">
                        <span v-if="$store.state.exchangeRate" style="color: #717579">
                            ≈ <ui-fiat v-bind:tonValue="price"/>
                        </span>
                    </template>
                </span>
                <span class="tx-table__cell" v-else>–</span>
            </td>

            <!-- <td>
                <div class="tx-table__cell tx-table__cell--align-center">
                    <div class="tx-table__badge tx-table__badge--burn" v-if="!type">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-burn />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.burn') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--sale" v-if="type === 'put-up-for-sale'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-sale />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.sale') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--sent" v-if="type === 'transfer'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-sent />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.sent') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--bid" v-if="type === 'put-up-for-auction'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-bid />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.bid') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--mint" v-if="type === 'mint'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-mint />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.mint') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--cancel" v-if="type === 'cancel-sale'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-cancel />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.cancel') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--cancel" v-if="type === 'cancel-auction'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-cancel-bid />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.cancel') }}
                            </div>
                        </div>
                    </div>

                    <div class="tx-table__badge tx-table__badge--sold" v-if="type === 'sold'">
                        <div class="tx-table__badge--items">
                            <div class="tx-table__badge--icon">
                                <icon-tx-sold />
                            </div>
                            <div class="tx-table__badge--value">
                                {{ $t('nft.item.badge.sold') }}
                            </div>
                        </div>
                    </div>
                </div>
            </td> -->
            <td>
                <div class="tx-table__cell">
                    <template v-if="oldOwner">
                        <ui-address v-bind:address="oldOwner" hide-name/>
                    </template>
                    <template v-else-if="owner">
                        <ui-address v-bind:address="owner" hide-name/>
                    </template>

                    <div class="tx-table-action-icon" v-if="newOwner">
                        <arrow-right />
                    </div>

                    <template v-if="newOwner">
                        <ui-address v-bind:address="newOwner" hide-name />
                    </template>
                </div>
                <!-- <div class="tx-table__cell tx-table__cell--align-left" v-if="oldOwner">
                    <ui-address v-bind:address="oldOwner" hide-name/>
                </div>
                <div class="tx-table__cell tx-table__cell--align-left" v-else-if="owner">
                    <ui-address v-bind:address="owner" hide-name/>
                </div>
                <div class="tx-table__cell tx-table__cell--align-left" v-else>–</div> -->
            </td>
            <!-- <td>
                <span class="tx-table__badge tx-table__badge--service" style="text-transform: uppercase;">
                    {{ $t('nft.item.to_tx') }}
                </span>
            </td> -->
            <!-- <td>
                <div class="tx-table__cell" v-if="newOwner">
                    <ui-address v-bind:address="newOwner" hide-name />
                </div>
                <div class="tx-table__cell" v-else>–</div>
            </td> -->
            <td>
                <ui-timeago class="tx-table__cell tx-table__cell--align-right" v-bind:timestamp="time * 1000"/>
            </td>
        </tr>
    </tbody>
</template>

<script>
// import IconTxChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconTxBurn from '@img/icons/tonscan/badges/burn.svg?vue';
import IconTxSale from '@img/icons/tonscan/badges/sale.svg?vue';
import IconTxSent from '@img/icons/tonscan/badges/sent.svg?vue';
import IconTxBid from '@img/icons/tonscan/badges/bid.svg?vue';
import IconTxMint from '@img/icons/tonscan/badges/mint.svg?vue';
import IconTxCancel from '@img/icons/tonscan/badges/cancel.svg?vue';
import IconTxCancelBid from '@img/icons/tonscan/badges/cancel-bid.svg?vue';
import IconTxSold from '@img/icons/tonscan/badges/sold.svg?vue';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';

export default {
    props: {
        hash: String,
        time: Number,
        owner: String,
        oldOwner: String,
        newOwner: String,
        price: String,
        type: String,
    },

    computed: {
        transactionRoute() {
            return {
                name: 'tx',
                params: {
                    hash: this.hash,
                },
            };
        },
    },

    components: {
        // IconTxChain,
        IconTxBurn,
        IconTxSale,
        IconTxSent,
        IconTxBid,
        IconTxMint,
        IconTxCancel,
        IconTxCancelBid,
        IconTxSold,
        ArrowRight,
    },
};
</script>
<style lang="scss">
.tx-table-cell-link-badge,
.tx-table-cell-link-badge:hover {
    text-decoration: none;
}

.tx-table-price-ton {
    margin-right: 4px;
}
</style>
