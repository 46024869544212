<template>
    <div
        class="card-status"
        v-bind:class="{
            'card-status__skeleton': traceStatus === undefined,
            'card-status__success': traceStatus === true,
            'card-status__failed': traceStatus === false,
        }"
    >
        <div class="card-status-items">
            <div class="card-status-icon">
                <div v-if="traceStatus === undefined"  class="card-status-skeleton-image">
                    <span class="skeleton">Unknown</span>
                </div>
                <template v-else-if="traceStatus">
                    <icon-success />
                </template>
                <template v-else>
                    <icon-failed />
                </template>
            </div>
            <div class="card-status-body">
                <div class="card-status-info">
                    <div class="card-status-title">
                        <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                        <template v-else-if="traceStatus">
                            {{ $t('tx.status_success') }}
                        </template>
                        <template v-else>
                            {{ $t('tx.status_failed') }}
                        </template>
                    </div>
                    <div class="card-status-value" v-if="!isMobile">
                        <span v-if="traceStatus === undefined" class="skeleton">EQDCHV6...LoH</span>
                        <template v-else-if="traceStatus">
                            <div v-if="actionTransfer && actionType === 'TonTransfer'" class="status-desc-items">
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.sender.address" />
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.sent') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.amount, 9) }}
                                </div>
                                <div class="status-desc-ticker">
                                    TON
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.to') }}</span>
                                </div>
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.recipient.address" />
                                </div>
                            </div>
                            <div v-else-if="actionTransfer && actionType === 'JettonTransfer'" class="status-desc-items">
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.sender.address" />
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.sent') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.amount, actionDetails.jetton.decimals) }}
                                </div>
                                <div class="status-desc-ticker">
                                    <ui-link v-bind:to="{ name: 'jetton', params: { address: canonizeAddress(actionDetails.jetton.address) }}" class="jetton-list-link">
                                        {{ actionDetails.jetton.symbol || actionDetails.jetton.address }}
                                    </ui-link>
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.to') }}</span>
                                </div>
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.recipient.address" />
                                </div>
                            </div>
                            <div v-else-if="actionTransfer && actionType === 'JettonSwap' && !actionDetails.amount_in" class="status-desc-items">
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.user_wallet.address" />
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.swap') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.ton_in, 9) }}
                                </div>
                                <div class="status-desc-ticker">
                                    TON
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.to') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.amount_out, actionDetails.jetton_master_out.decimals) }}
                                </div>
                                <div class="status-desc-ticker">
                                    <ui-link v-bind:to="{ name: 'jetton', params: { address: canonizeAddress(actionDetails.jetton_master_out.address) }}" class="jetton-list-link">
                                        {{ actionDetails.jetton_master_out.symbol || actionDetails.jetton_master_out.address }}
                                    </ui-link>
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.at') }}</span>
                                </div>
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.router.address" />
                                </div>
                            </div>
                            <div v-else-if="actionTransfer && actionType === 'JettonSwap' && !actionDetails.amount_out" class="status-desc-items">
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.user_wallet.address" />
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.swap') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.amount_in, actionDetails.jetton_master_in.decimals) }}
                                </div>
                                <div class="status-desc-ticker">
                                    <ui-link v-bind:to="{ name: 'jetton', params: { address: canonizeAddress(actionDetails.jetton_master_in.address) }}" class="jetton-list-link">
                                        {{ actionDetails.jetton_master_in.symbol || actionDetails.jetton_master_in.address }}
                                    </ui-link>
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.to') }}</span>
                                </div>
                                <div class="status-desc-count">
                                    {{ $ton(actionDetails.ton_out, 9) }}
                                </div>
                                <div class="status-desc-ticker">
                                    TON
                                </div>
                                <div class="status-desc-pad">
                                    <span class="card-status-muted-text">{{ $t('transactionPage.at') }}</span>
                                </div>
                                <div class="status-desc-address">
                                    <ui-address v-bind:address="actionDetails.router.address" />
                                </div>
                            </div>
                            <template v-else>
                                <span class="card-status-st-text">{{ $t('tx.status_success_description') }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="card-status-st-text">{{ $t('tx.status_failed_description') }}</span>
                        </template>
                    </div>
                </div>
                <div class="card-status-time">
                    <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                    <span v-else>
                        <!-- <ui-datetime v-bind:timestamp="timestamp" v-bind:monthFormatDate="true" /> -->
                        {{ formatValidatorDate(timestamp, this.$i18n.locale) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconSuccess from '@img/icons/status/success.svg?inline';
import IconFailed from '@img/icons/status/failed.svg?inline';
import { formatValidatorDate } from '~/helpers.js';
import { canonizeAddress } from '~/tonweb.js';

export default {
    props: {
        isSuccess: Boolean,
        computeExitCode: String,
        actionResultCode: String,
        address: String,
        timestamp: Number,
        traceStatus: Boolean,
        traceDetails: Object,
    },

    data() {
        return {
            actionTransfer: false,
            actionDetails: false,
            actionType: null,
        };
    },

    computed: {
        extractedAction() {
            const actions = this.traceDetails?.actions;

            if (Array.isArray(actions) && actions.length === 1) {
                const action = actions[0];
                const actionKeys = Object.keys(action);

                if (actionKeys.length === 1) {
                    return action[actionKeys[0]];
                }
            }

            return null;
        },
    },

    watch: {
        traceDetails: {
            handler: 'handleTraceDetailsChange',
            deep: true,
            immediate: true,
        },
    },

    methods: {
        formatValidatorDate,
        canonizeAddress,
        handleTraceDetailsChange(newDetails) {
            if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'TonTransfer') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].TonTransfer;

                const sender = {
                    ...action.sender,
                    address: canonizeAddress(action.sender.address, { type: action.sender.is_wallet ? 'wallet' : undefined }),
                };

                const recipient = {
                    ...action.recipient,
                    address: canonizeAddress(action.recipient.address, { type: action.recipient.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    sender,
                    recipient,
                };

                this.actionType = 'TonTransfer';
            } else if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'JettonTransfer') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].JettonTransfer;

                const sender = {
                    ...action.sender,
                    address: canonizeAddress(action.sender.address, { type: action.sender.is_wallet ? 'wallet' : undefined }),
                };

                const recipient = {
                    ...action.recipient,
                    address: canonizeAddress(action.recipient.address, { type: action.recipient.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    sender,
                    recipient,
                };

                this.actionType = 'JettonTransfer';
            } else if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'JettonSwap') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].JettonSwap;

                const router = {
                    ...action.router,
                    address: canonizeAddress(action.router.address, { type: action.router.is_wallet ? 'wallet' : undefined }),
                };

                const user_wallet = {
                    ...action.user_wallet,
                    address: canonizeAddress(action.user_wallet.address, { type: action.user_wallet.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    router,
                    user_wallet,
                };

                this.actionType = 'JettonSwap';
            } else {
                this.actionTransfer = false;
                this.actionDetails = null;
            }
        },
    },

    components: {
        IconSuccess, IconFailed,
    },
};
</script>

<style lang="scss">
.card-status {
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    &__skeleton {
        background: var(--success-skeleton-transaction-background);
        border: 1px solid var(--success-skeleton-transaction-border);
    }
    &__success {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--success-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
            white-space: nowrap;
        }

        .card-status-icon {
            color: var(--success-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
    &__failed {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--fail-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }

        .card-status-icon {
            color: var(--fail-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
}

.card-status-address {
    width: 150px;
    font-size: 14px;
}

.card-status-items {
    display: flex;
    gap: 20px;
    align-items: center;
}

.card-status-icon {
    width: 43px;
    svg {
        display: block;
    }
}

.card-status-body {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.card-status-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500;
}

.card-status-skeleton-image {
    width: 43px;
    height: 43px;
    border-radius: 50px;
    overflow: hidden;
}

.card-status-skeleton-image .skeleton {
    height: 100%;
    width: 100%;
}

.status-desc-address {
    max-width: 240px;
}

.status-desc-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
}

.status-desc-count {
    font-weight: 500;
}

.status-desc-ticker {
    font-weight: 500;
}

@media all and (max-width: 599px) {

    .card-status-body {
        flex-direction: column;
        flex-grow: inherit;
    }

    .card-status-address {
        margin: 10px auto 10px auto;
    }
}
</style>
