var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"card-chain-header"},[_c('div',{staticClass:"card-chain-header__title"},[_vm._v("\n            "+_vm._s(_vm.title === 'masterchain' ? _vm.$t('blocks.last_masterchain_blocks_title') : _vm.$t('blocks.last_workchain_blocks_title'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-chain-header__link"},[(_vm.blocks.length > 0)?_c('ui-link',{attrs:{"to":{
                name: 'blocks',
                query: { wc: _vm.blocks[0].workchain },
            }}},[_vm._v("\n                "+_vm._s(_vm.$t('common.show_more'))+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"tx-history-wrap desktop-table"},[_c('table',{staticClass:"ui-table last-blocks-mini-table"},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('blocks.block'))}}),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('blocks.transactions'))}}),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('common.time'))}})])]),_vm._v(" "),(_vm.blocks.length === 0)?_c('tbody',_vm._l((15),function(i){return _c('tr',{key:`blocks_mini_table_skeleton_${i}`},[_vm._m(0,true),_vm._v(" "),_vm._m(1,true),_vm._v(" "),_vm._m(2,true)])}),0):_c('tbody',_vm._l((_vm.blocks),function(block){return _c('tr',{key:`${block.workchain}:${block.seqno}`,staticClass:"new-row-animation"},[_c('td',[_c('ui-link',{attrs:{"to":{ name: 'block', params: { ...block } }}},[_vm._v("\n                            "+_vm._s(block.seqno)+"\n                        ")])],1),_vm._v(" "),_c('td',{domProps:{"textContent":_vm._s(block.tx_count)}}),_vm._v(" "),_c('td',[_c('ui-datetime',{attrs:{"hide-date":"","timestamp":block.gen_utime}})],1)])}),0)])]),_vm._v(" "),_c('div',{staticClass:"card-chain-footer"},[(_vm.blocks.length > 0)?_c('ui-link',{staticClass:"card-chain-show-more",attrs:{"to":{
                name: 'blocks',
                query: { wc: _vm.blocks[0].workchain },
            }}},[_c('div',{staticClass:"card-chain-show-more__title"},[_vm._v("\n                "+_vm._s(_vm.$t('common.show_more'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"card-chain-show-more__icon"},[_c('IconArrow')],1)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("123456789")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("21:17:22")])])
}]

export { render, staticRenderFns }