<template>
    <tbody style="position: relative;">
        <tr>
            <td>
                <div class="tx-table__cell">
                    <event-overview-icon
                        v-bind:name="action.simple_preview.name"
                        v-bind:event="type"
                        v-bind:action="action"
                    />
                </div>
            </td>
            <td>
                <div class="tx-table__cell">
                    <template v-if="type === 'JettonSwap' || type === 'NftItemTransfer' || type === 'JettonTransfer'">
                        <ui-address
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'TonTransfer' }"
                            v-bind:address="processedAddressesTo"
                            v-bind:alias="processedNameTo"
                        />
                        <div class="tx-table-action-icon" v-if="event || type === 'TonTransfer'">
                            <arrow-right />
                        </div>
                        <ui-address v-if="event || type === 'TonTransfer'"
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'TonTransfer' }"
                            class="ui-address-event-overview"
                            v-bind:address="processedAddressesFrom"
                            v-bind:alias="processedNameFrom"
                        />
                    </template>
                    <template v-else>
                        <ui-address
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'TonTransfer' }"
                            v-bind:address="processedAddressesFrom"
                            v-bind:alias="processedNameFrom"
                        />
                        <div class="tx-table-action-icon" v-if="event || type === 'TonTransfer'">
                            <arrow-right />
                        </div>
                        <ui-address v-if="event || type === 'TonTransfer'"
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'TonTransfer' }"
                            v-bind:address="processedAddressesTo"
                            v-bind:alias="processedNameTo"
                        />
                    </template>
                </div>
            </td>

            <td>
                <div class="tx-table__cell tx-table-payload">
                    <template v-if="type === 'SmartContractExec'">
                        <span class="tx-table-payload-text muted">{{ actionDetails?.operation }}</span>
                    </template>

                    <template v-if="type === 'ContractDeploy'">
                        <span class="tx-table-payload-text muted" v-if="actionDetails.interfaces.length > 0">interfaces: {{ JSON.stringify(actionDetails.interfaces) }}</span>
                    </template>

                    <template v-if="type === 'JettonTransfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.comment }}</span>
                    </template>

                    <template v-if="type === 'NftItemTransfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.comment }}</span>
                    </template>

                    <template v-if="type === 'TonTransfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.comment }}</span>
                    </template>
                </div>
            </td>

            <td class="column-wide">
                <div class="tx-table__cell tx-table__cell--align-right">
                    <event-overview-badge
                        v-bind:type="type"
                        v-bind:actionDetails="actionDetails"
                    />
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { canonizeAddress } from '~/tonweb.js';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import EventOverviewBadge from './EventOverviewBadge.vue';
import EventOverviewIcon from './EventOverviewIcon.vue';

export default {
    props: {
        action: Object,
        actionDetails: Object,
        type: String,
        event: Boolean,
    },

    computed: {
        processedAddressesFrom() {
            if (this.action.simple_preview.accounts[0]?.address) {
                const canonize = canonizeAddress(this.action.simple_preview.accounts[0]?.address, {
                    type: this.action.simple_preview.accounts[0]?.is_wallet ? 'wallet' : undefined,
                });
                // console.log('dddd', this.action.simple_preview.accounts[0]);
                return canonize;
            }
            return null;
        },

        processedNameFrom() {
            if (this.action.simple_preview.accounts[0]?.name) {
                return this.action.simple_preview.accounts[0]?.name;
            }
            return null;
        },

        processedAddressesTo() {
            if (this.action.simple_preview.accounts[1]?.address) {
                const canonize = canonizeAddress(this.action.simple_preview.accounts[1]?.address, {
                    type: this.action.simple_preview.accounts[1]?.is_wallet ? 'wallet' : undefined,
                });

                return canonize;
            }
            return null;
        },

        processedNameTo() {
            if (this.action.simple_preview.accounts[1]?.name) {
                return this.action.simple_preview.accounts[1]?.name;
            }
            return null;
        },
    },

    components: {
        ArrowRight,
        EventOverviewBadge,
        EventOverviewIcon,
    },
};
</script>

<style lang="scss">
.tx-table-address-route {
    display: flex;
    align-items: center;
}

.tx-table-payload-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ui-address-event-overview {
    max-width: 180px;
}
@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
</style>
