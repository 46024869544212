<script>
import IconHistory from '@img/icons/material-duotone/history.svg?inline';
import IconContract from '@img/icons/material-duotone/data-object.svg?inline';
import IconNft from '@img/icons/material-duotone/view-in-ar.svg?inline';
import IconToken from '@img/icons/material-duotone/toll.svg?inline';
import IconWhitelist from '@img/icons/material-duotone/groups.svg?inline';

import UiTabs from '~/components/UiTabs.vue';
import TabUserNfts from '~/components/address/TabUserNfts.vue';
import TabUserTokens from '~/components/address/TabUserTokens.vue';
import TabContractSources from '~/components/address/Verifier/Verifier.vue';
import TxRoot from '~/components/address/TxRoot.vue';
import TabVestingWhitelist from './TabVestingWhitelist.vue';

export default {
    props: {
        isActive: Boolean,
        address: {
            type: String,
            required: true,
        },
        sender: String,
        whitelist: Array,
    },

    render(createComponent) {
        return createComponent(UiTabs, {
            class: 'card card--tabbed',
            props: {
                tabs: this.tabs,
                changeUrlHash: true,
            },
            on: this.$listeners, // passthru listeners from parent
            scopedSlots: this.$scopedSlots,
        });
    },

    computed: {
        tabs() {
            const props = { address: this.address };
            const key = this.address; // keepalive key

            return [{
                key: 'events',
                text: this.$t('address.tab_events'),
                icon: IconHistory,
                content: {
                    key, props,
                    eager: true,
                    component: TxRoot,
                },
            }, {
                key: 'whitelist',
                text: this.$t('address.tab_whitelist'),
                icon: IconWhitelist,
                content: { key, props: {
                    sender: this.sender,
                    whitelist: this.whitelist,
                },
                component: TabVestingWhitelist,
                },
            }, {
                key: 'tokens',
                text: this.$t('address.tab_tokens'),
                icon: IconToken,
                content: { key, props, component: TabUserTokens },
            }, {
                key: 'nfts',
                text: this.$t('address.tab_nfts'),
                icon: IconNft,
                content: { key, props, component: TabUserNfts },
            }, {
                key: 'source',
                text: this.$t('address.tab_contract'),
                icon: IconContract,
                content: {
                    key,
                    component: TabContractSources,
                    props: {
                        address: this.address,
                        isActive: this.isActive,
                    },
                },
            }];
        },
    },
    components: {
        UiTabs,
        TxRoot,
    },
};
</script>
