<template>
    <div class="card market-info-block">
        <div class="card-market-header featured-jettons-header">
            <div class="card-market-title">
                <span>{{ $t("market_blocks.headers.featured_jettons") }}</span>
            </div>
        </div>
        <agile
            class="featured-jettons-carousel"
            v-bind:navButtons="false"
            v-bind:dots="true"
            v-bind:autoplay="true"
            v-bind:speed="600"
            v-bind:autoplaySpeed="9000"
            v-bind:clientWidth="100"
            v-bind:swipeDistance="350"
            v-on:after-change="showCurrentJetton($event)"
        >
            <ChartJetton
                v-for="jetton in jettons"
                v-bind:labels="jetton.labels"
                v-bind:datasets="jetton.datasets"
                v-bind:price="jetton.price"
                v-bind:address="jetton.address"
                v-bind:name="jetton.name"
                v-bind:symbol="jetton.symbol"
                v-bind:image="jetton.image"
                v-bind:key="jetton.address"
            />
        </agile>
    </div>
</template>

<script>
import { getDaysAgoTimestamp } from '~/helpers';
import { getJettonChart } from '~/api/tonapi';
import { decimateData } from '~/decimation';
import ChartJetton from './chartJetton.vue';

export default {
    data() {
        return {
            jettons: [
                {
                    labels: undefined,
                    datasets: undefined,
                    price: undefined,
                    address: 'EQCuPm01HldiduQ55xaBF_1kaW_WAUy5DHey8suqzU_MAJOR',
                    name: 'Major',
                    symbol: 'MAJOR',
                    image: 'https://cache.tonapi.io/imgproxy/GK24g2FMJyJr7v24O2WX2rO02tsJ7_72ZqxlexuXmrc/rs:fill:200:200:1/g:no/aHR0cHM6Ly9tYWpvci5ib3QvaW1hZ2VzL3Rhc2tzL21ham9yX2xvZ28uc3Zn.webp',
                },
                {
                    labels: undefined,
                    datasets: undefined,
                    price: undefined,
                    address: 'EQC47093oX5Xhb0xuk2lCr2RhS8rj-vul61u4W2UH5ORmG_O',
                    name: 'Gram',
                    symbol: 'GRAM',
                    image: 'https://cache.tonapi.io/imgproxy/lNoY3YdNeBug53ixjK6hxT6XIX3_xoIYNqv-ykIQ1Aw/rs:fill:200:200:1/g:no/aHR0cHM6Ly9ncmFtY29pbi5vcmcvaW1nL2ljb24ucG5n.webp',
                },
                {
                    labels: undefined,
                    datasets: undefined,
                    price: undefined,
                    address: 'EQACLXDwit01stiqK9FvYiJo15luVzfD5zU8uwDSq6JXxbP8',
                    name: 'Spintria',
                    symbol: 'SP',
                    image: 'https://cache.tonapi.io/imgproxy/1n6JgCgUl5OVenawa4IQnTuZCDwSxpB3gXkW_1kEdGc/rs:fill:200:200:1/g:no/aHR0cHM6Ly9zdGF0aWMueW91c3BhY2UuZGV2L3RvbmNvaW4uanBn.webp',
                },
                {
                    labels: undefined,
                    datasets: undefined,
                    price: undefined,
                    address: 'EQB8O0JJ-hqeDAqDC1OG6zPYBfpV-QzwPed0kpcbILXsmAxG',
                    name: 'Gem',
                    symbol: 'GEM',
                    image: 'https://cache.tonapi.io/imgproxy/5S5XSBapll3Tlyf5qsRkZueCrn8KFmD06F3ICDNWhck/rs:fill:200:200:1/g:no/aHR0cHM6Ly90Lm1lL2kvdXNlcnBpYy8zMjAvZ2VtaHEuanBn.webp',
                },
            ],
        };
    },

    methods: {
        showCurrentJetton(e) {
            const index = e.currentSlide;

            const j = this.jettons[index];
            if (j.price && j.labels && j.datasets) return;

            this.loadChartData(index);
        },
        async loadChartData(index) {
            let indexer;
            const startDate = getDaysAgoTimestamp(7);

            const chartData = await getJettonChart({
                token: this.jettons[index].address,
                currency: 'USD',
                start_date: startDate,
                end_date: Math.floor(Date.now() / 1000),
            });

            if (chartData.points.length > 0) {
                indexer = chartData.points.slice(1).sort((a, b) => a[0] - b[0]);
            }

            const lastPoint = indexer[indexer.length - 1];

            this.jettons[index].labels = decimateData(indexer.map(([timestamp]) => timestamp * 1000), 4);
            this.jettons[index].labels.splice(0, 1);

            const dataset = indexer.map(([, value]) => value);
            const priceDataset = {
                data: decimateData(dataset, 4),
            };

            this.jettons[index].datasets = [priceDataset];

            this.jettons[index].price = lastPoint[1];
        },
    },

    components: {
        ChartJetton,
    },
};
</script>

<style lang="scss">
.agile {
    display: flex;
    flex-direction: column;

    &__actions {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
    }
    &__slides {
        align-items: flex-start;
    }
    &__slide {
        // opacity: 0;
    }
    &__slide--active {
        // opacity: 1;
    }
    &__list {
        order: 2;
    }

    &__dots {
        margin: 0;
        gap: 5px;
        padding: 15px 15px 5px 75px;
        background: var(--dots-slider);
        border-radius: 0 12px 0 0;
    }

    &__dot button {
        border-radius: 50px;
        height: 4px;
        width: 15px;
        padding: 8px 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        border: 0;
        position: relative;
        cursor: pointer;
    }

    &__dot button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 50px;
        transform: translate(0%, -50%);
        background: var(--button-slider-opacity);
    }

    &__dot--current button {
        width: 25px;
        ::after {
            background: var(--button-slider);
        }
    }

    &__dot--current button:after {
        background: var(--button-slider);
    }
}

.card-market-body {
    width: 100%;
}

.chart-bar-mini__graph {
    max-height: 160px;
    height: 104px;
}
@media screen and (max-width: 599px) {
}

.featured-jettons-header {
    position: absolute;
    max-width: 300px;
}

.featured-jettons-carousel .agile__dots {
    padding: 12px 15px 0 75px;
}
</style>
