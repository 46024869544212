<template>
    <section>
        <section v-if="state.isError">
            <div class="alert" v-text="$t('error.invalid_tx')" />
        </section>

        <Status
            v-bind:computeExitCode="transactionInfo.computeExitCode"
            v-bind:actionResultCode="transactionInfo.actionResultCode"
            v-bind:address="transactionInfo.address"
            v-bind:timestamp="traceTime"
            v-bind:traceStatus="traceStatus"
            v-show="!state.isError"
            v-bind:traceDetails="traceDetails"
        />

        <transaction-tabs
            v-show="!state.isError"
            v-bind:traceId="traceId"
            v-bind:timeStart="timeStart"
            v-bind:traceActions="traceActions"
            v-bind:traceEmpty="traceEmpty"
            v-bind:trace="trace"
        />

        <div v-show="!state.isError && trace" class="trace">
            <Diagram
                v-bind:trace="trace"
                v-bind:connections="connections"
                v-on:node-clicked="handleNodeClicked"
            />
        </div>

        <Transaction
            v-if="transactionData"
            v-bind:transactionData="transactionData"
            v-bind:transactionDataTonapi="transactionDataTonapi"
        />

        <div class="open-details muted" v-else-if="Object.keys(trace).length > 0">
            {{$t('tx.open_details')}}
            <!--<span class="open-details__link" @click="openFirstItem">{{$t('tx.click_on_item')}}</span>{{$t('tx.open_details')}}-->
        </div>

        <div class="transaction-button" v-if="state.showButton && !state.isLoading">
            <div class="transaction-button__items" v-on:click="handleButtonClicked">
                <div class="transaction-button__icon">
                    <IconTxTypeOrdinary />
                </div>
                <div class="transaction-button__value">
                    <span>{{ $t('tx.button_transactions') }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import IconTxTypeOrdinary from '@primer/octicons/build/svg/git-commit-24.svg?inline';
// import IconTrace from '@primer/octicons/build/svg/note-24.svg?inline';
import { goToDevExplorerMixin } from '~/mixins';
import { base64ToHex, toBase64Web } from '~/utils.js';
import { getTransactionByHashOrInMessageHash, getTransactionByInMsgHash } from '~/api';
import { getTransactionTraceV3 } from '~/api/toncenterPreview';
import { formatTimestamp } from '~/helpers';
import { getEventStatus, getTransactionTrace } from '~/api/tonapi';
import Transaction from './Transaction/Transaction.vue';
import Diagram from './Diagram/Diagram.vue';
import TransactionTabs from './TransactionTabs.vue';
import Status from './Tabs/Status.vue';

export default {
    props: {
        hash: String,
    },

    data() {
        return {
            fees: {
                total: undefined,
                storage: undefined,
                other: undefined,
            },
            transactionInfo: {
                address: undefined,
                addressBook: undefined,
                type: undefined,
                isSuccess: undefined,
                exitCode: undefined,
                computeExitCode: undefined,
                actionResultCode: undefined,
                computeVmSteps: undefined,
                timestamp: undefined,
            },
            state: {
                isLoading: true,
                isError: false,
                msgOpen: false,
                redirect: false,
                showButton: false,
                msgHash: false,
            },
            hashData: {
                base64: undefined,
                hex: undefined,
                lt: undefined,
            },
            outMsgs: [],
            trace: {},
            traceId: null,
            timeStart: null,
            connections: [],
            transaction: null,
            transactionData: null,
            transactionDataTonapi: null,
            firstTransaction: null,
            traceTonapi: null,
            traceStatus: undefined,
            traceTime: null,
            traceActions: undefined,
            traceEmpty: false,
            traceDetails: null,
        };
    },

    computed: {
        isGettingByMsgHash() {
            return this.$route.name === 'tx_by_msg_hash';
        },

        devExplorerUrl() {
            return `/transaction?account=${this.transactionInfo.address}&lt=${this.hashData.lt}&hash=${this.hashData.hex}`;
        },

        formattedDate() {
            return formatTimestamp(this.timeStart);
        },
    },

    created() {
        const hashLength = this.$route.params.hash.length;
        if (hashLength !== 64) {
            this.$router.replace(this.$localizeRoute({
                name: this.$route.name,
                params: { hash: base64ToHex(this.$route.params.hash) },
            }));
            this.state.redirect = true;
            return;
        }

        this.loadData();
        this.loadTrace();
    },

    watch: {
        $route() {
            if (!this.state.redirect) {
                this.loadData();
                this.loadTrace();
            }
        },
        transaction() {
            if (this.transaction) this.loadTransaction();
        },
        'state.redirect': 'loadTrace',
        'state.msgHash': 'loadTrace',
    },

    methods: {
        async loadData() {
            this.state.isLoading = true;
            this.state.isError = false;

            this.transactionData = null;
            this.transactionDataTonapi = null;

            try {
                const apiMethod = this.isGettingByMsgHash ? getTransactionByInMsgHash : getTransactionByHashOrInMessageHash;
                const { transactions, address_book } = await apiMethod(this.hash);
                const tx = transactions[0];

                this.updateTransactionInfo(tx, address_book);
                this.fixInMsgHash(tx);
            } catch (error) {
                this.state.isError = true;
                console.error(error);
            } finally {
                this.state.isLoading = false;
            }
        },

        async loadTrace() {
            this.traceStatus = undefined;
            this.traceActions = undefined;
            this.trace = {};
            this.connections = [];

            let newHash = base64ToHex(this.hash);
            // Get trace from preview.toncenter.com
            try {
                if (this.$route.params.hash.length === 64) {
                    newHash = this.hash;
                }
                const res = await getTransactionTraceV3(newHash, !!this.state.msgHash);
                this.trace = res.trace;
                this.connections = res.connections;
                this.firstTransaction = res.trace.transaction.hash;

                this.timeStart = res.series.timeStart * 1000;
                this.traceId = res.series.traceId;
            } catch (error) {
                console.error(error);
            }

            // Get trace from tonapi
            try {
                const resTonapi = await getTransactionTrace(newHash);
                const resEventStatus = await getEventStatus(newHash);

                this.traceDetails = resEventStatus;

                const hasFailedStatus = resEventStatus.actions.some(action => action.status === 'failed');

                this.traceStatus = !hasFailedStatus;

                // console.log('EventStatus', resEventStatus);

                this.traceActions = resEventStatus.actions;
                this.traceTime = resEventStatus.timestamp;

                if (resEventStatus.actions.length === 0) {
                    this.traceEmpty = true;
                }

                this.traceTonapi = resTonapi.trace;
            } catch (error) {
                console.error(error);
            }
        },

        async loadTransaction() {
            try {
                /* eslint-disable-next-line no-inner-declarations */
                function findTransaction(trace, targetHash) {
                    if (trace.transaction.hash === targetHash) return trace.transaction;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const child of trace.children || []) {
                        const result = findTransaction(child, targetHash);
                        if (result) return result;
                    }
                    return null;
                }

                this.transactionData = findTransaction(this.trace, this.transaction) || null;
                // console.log('toncenter transaction', this.transactionData);

                /* eslint-disable-next-line no-inner-declarations */
                function findTransactionTonapi(traceTonapi, targetHash) {
                    if (traceTonapi.transaction.hash === base64ToHex(targetHash)) return traceTonapi.transaction;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const child of traceTonapi.children || []) {
                        const result = findTransaction(child, base64ToHex(targetHash));
                        if (result) return result;
                    }
                    return null;
                }

                this.transactionDataTonapi = findTransactionTonapi(this.traceTonapi, this.transaction) || null;
                // if (!this.transactionDataTonapi) console.log('Transaction not found.');
                // console.log('tonapi transaction', this.transactionDataTonapi);
            } catch (error) {
                console.error(error);
            }
        },

        updateTransactionInfo(tx, addressBook) {
            this.transactionInfo.addressBook = addressBook;
            this.transactionInfo.address = addressBook[tx.account].user_friendly;

            const newWalletTxSuccess = tx.description?.action?.result_code === undefined && tx.description?.compute_ph?.exit_code === undefined;
            const executionSuccess = tx.description?.action?.action_result_code !== null && parseInt(tx.description?.action?.result_code, 10) <= 1;
            this.transactionInfo.isSuccess = newWalletTxSuccess || executionSuccess;
            this.transactionInfo.exitCode = this.transactionInfo.isSuccess
                ? tx.description?.action?.result_code
                : tx.description?.compute_ph?.exit_code;
            this.transactionInfo.computeExitCode = tx.description?.compute_ph?.exit_code || null;
            this.transactionInfo.actionResultCode = tx.description?.action?.result_code || null;
            this.transactionInfo.computeVmSteps = tx.description?.compute_ph?.vm_steps;
            this.transactionInfo.timestamp = parseInt(`${tx.now}000`, 10);

            this.fees.total = tx.total_fees;
            this.fees.storage = tx.description?.storage_ph?.storage_fees_collected || 0;
            this.fees.other = tx.total_fees - this.fees.storage || 0;

            this.outMsgs = tx.out_msgs.map(Object.freeze);
            this.inMsg = tx.in_msg ? Object.freeze(tx.in_msg) : undefined;

            this.hashData.hex = base64ToHex(tx.hash);
            this.hashData.base64 = tx.hash;
            this.hashData.lt = tx.lt;
        },

        fixInMsgHash(tx) {
            if (tx?.in_msg?.hash && toBase64Web(tx.in_msg.hash) === toBase64Web(this.hash)) {
                this.state.msgHash = true;
                this.$router.replace(this.$localizeRoute({
                    name: 'tx_by_msg_hash',
                    params: { hash: toBase64Web(this.hash) },
                }));
            }
        },

        handleNodeClicked(data) {
            this.transaction = data.transaction.hash;
            this.state.showButton = false;
        },

        handleButtonClicked() {
            this.state.showButton = false;
            this.transaction = this.firstTransaction;
        },

        toggleMsg() {
            this.state.msgOpen = !this.state.msgOpen;
        },
    },

    metaInfo() {
        return {
            title: this.$t('tx.meta.title', { hash: this.hash }),
            meta: [{ property: 'robots', content: 'noindex' }],
        };
    },

    components: {
        Diagram,
        Status,
        IconTxTypeOrdinary,
        Transaction,
        TransactionTabs,
        // IconTrace,
    },

    mixins: [goToDevExplorerMixin],
};
</script>

<style lang="scss">
.tx-status {
    display: flex;
    align-items: center;
    font-weight: 500;

    &--failed {
        color: var(--page-tx-status-error-color);
    }

    &--success {
        color: var(--page-tx-status-success-color);
    }

    &__icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
}

.tx-flow-schematics {
    display: flex;
    align-items: center;
    border: 1px solid var(--page-tx-flow-diagram-border-color);
    overflow: hidden;
    border-radius: 8px;
}

.tx-flow-schematics-step {
    display: flex;
    align-items: center;

    &__inner {
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
    }

    &__phase {
        font-size: 12px;
    }

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-color: var(--page-tx-flow-diagram-border-color);
        border-width: 0 1px 1px 0;
        border-style: solid;
        transform: rotate(-45deg);
        margin: 0 8px 0 -28px;
        user-select: none;
        pointer-events: none;
    }

    &:last-child::after {
        display: none;
    }
}

.trace {
    margin: 0;
}

.card-timeago {
    color: var(--body-muted-text-color);
}

.trace {
    margin: 20px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.trace::-webkit-scrollbar {
  display: none;
}

.transaction-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    &__items {
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        border: 1px solid var(--body-light-muted-color);
        padding: 0 12px;
        line-height: 36px;
        border-radius: 7px;
        color: var(--body-text-color);
        transition: all 0.2s;
    }

    &__items:hover {
        border: 1px solid var(--body-muted-text-color);
        color: var(--body-text-color);
        text-decoration: none;
    }

    &__icon {
        width: 24px;
        color: var(--body-text-color);

        svg {
            display: block;
            fill: currentColor;
        }
    }

    &__value {
        color: var(--body-text-color);
        font-size: 14px;
        text-transform: uppercase;
    }
}

.open-details {
    text-align: center;
    font-size: 14px;

    &__link {
        text-decoration: underline;
        text-decoration-style: dashed;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}
</style>
