<template>
    <tbody style="position: relative;">
        <tr class="new-row-animation event-overview-row">
            <td class="tx-mobile-flex-content">

                <div class="tx-table-cell-icon tx-table-cell-icon--service">
                    <!-- <icon-out/> -->
                    <component v-bind:is="getIcon" />
                </div>

                <div class="tx-mobile-content">
                    <div class="tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper">
                        <div
                            class="tx-block-mobile-content__row tx-block-mobile-swap-items"
                            v-bind:class="type === 'NftItemTransfer' ? 'tx-block-nft-transfer' : ''"
                        >
                            <div class="tx-block-mobile-content__left">
                                <span class="muted tx-block-swap-name">
                                    {{ $t(`transactionPage.${this.action.type}`) }}
                                    <!-- {{ action.simple_preview.name }} -->
                                </span>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                <event-overview-badge
                                    v-bind:type="type"
                                    v-bind:actionDetails="actionDetails"
                                />
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__address">
                                <template v-if="type === 'JettonSwap' || type === 'NftItemTransfer' || type === 'JettonTransfer'|| type === 'TonTransfer'">
                                    <ui-address
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesTo"
                                        v-bind:alias="processedNameTo"
                                    />
                                    <div class="tx-table-action-icon" v-if="event">
                                        <arrow-right />
                                    </div>
                                    <ui-address v-if="event"
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesFrom"
                                        v-bind:alias="processedNameFrom"
                                    />
                                </template>
                                <template v-else>
                                    <ui-address
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesFrom"
                                        v-bind:alias="processedNameFrom"
                                    />
                                    <div class="tx-table-action-icon" v-if="event">
                                        <arrow-right />
                                    </div>
                                    <ui-address v-if="event"
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesTo"
                                        v-bind:alias="processedNameTo"
                                    />
                                </template>
                            </div>
                        </div>

                        <!-- <template v-if="type === 'SmartContractExec' && actionDetails?.operation">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.operation }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'ContractDeploy' && actionDetails.interfaces.length > 0">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents" v-if="actionDetails.interfaces.length > 0">interfaces: {{ JSON.stringify(actionDetails.interfaces) }}</span>
                                    </div>
                                </div>
                            </div>
                        </template> -->

                        <template v-if="type === 'JettonTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'NftItemTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'TonTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { canonizeAddress } from '~/tonweb.js';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconSwap from '@img/icons/tonscan/transactions/tx-swap.svg?vue';
import IconSmartContractExec from '@img/icons/tonscan/transactions/tx-contract.svg?vue';
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconBurn from '@img/icons/tonscan/transactions/tx-burn.svg?vue';
import IconAuctionBid from '@img/icons/tonscan/transactions/tx-bid.svg?vue';
import EventOverviewBadge from './EventOverviewBadge.vue';

/* eslint-disable "quote-props" */
const iconMap = Object.freeze({
    TonTransfer: IconOut,
    NftItemTransfer: IconOut,
    JettonTransfer: IconOut,
    ContractDeploy: IconSmartContractExec,
    SmartContractExec: IconSmartContractExec,
    JettonSwap: IconSwap,
    JettonBurn: IconBurn,
    AuctionBid: IconAuctionBid,
});
export default {
    props: {
        action: Object,
        actionDetails: Object,
        type: String,
        event: Boolean,
    },

    computed: {
        getIcon() {
            return iconMap[this.type] || IconChain;
        },

        processedAddressesFrom() {
            if (this.action.simple_preview.accounts[0]?.address) {
                const canonize = canonizeAddress(this.action.simple_preview.accounts[0]?.address, {
                    type: this.action.simple_preview.accounts[0]?.is_wallet ? 'wallet' : undefined,
                });

                return canonize;
            }
            return null;
        },

        processedNameFrom() {
            if (this.action.simple_preview.accounts[0]?.name) {
                return this.action.simple_preview.accounts[0]?.name;
            }
            return null;
        },

        processedAddressesTo() {
            if (this.action.simple_preview.accounts[1]?.address) {
                const canonize = canonizeAddress(this.action.simple_preview.accounts[1]?.address, {
                    type: this.action.simple_preview.accounts[1]?.is_wallet ? 'wallet' : undefined,
                });

                return canonize;
            }
            return null;
        },

        processedNameTo() {
            if (this.action.simple_preview.accounts[1]?.name) {
                return this.action.simple_preview.accounts[1]?.name;
            }
            return null;
        },
    },

    components: {
        ArrowRight,
        EventOverviewBadge,
        IconOut,
    },
};
</script>

<style lang="scss">
.event-overview-row {
    .ui-inline-nft {
        padding: 0!important;
        margin: 0!important;
        border-radius: 8px!important;

        &:hover {
            background: var(--nft-preview-hover-background)!important;
            color: var(--nft-preview-hover-color)!important;
        }
    }
}

.tx-block-mobile-swap-items {
    align-items: center;
}

.tx-block-mobile-content-swap-wrapper {
    display: flex;
    flex-direction: column;
}

.tx-block-mobile-content__address {
    display: inline-flex;
    min-width: 0;
    flex-shrink: 1;
}

.tx-block-swap-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tx-block-nft-transfer {
    padding-bottom: 5px;
}

.ui-address-event-overview {
    max-width: 180px;
}

@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
</style>
